import React, { useMemo } from 'react';
import Row from 'components/Elements/Row/Row';
import Value from 'components/Elements/Value/Value';
import ValueController from 'components/Elements/ValueController/ValueController';
import { config } from 'config/config';
import { useNavigate } from 'react-router';

const AmountPerAddress = ({addresses, token}: any) => {
const navigate = useNavigate();
  return useMemo(() => (
    addresses.map((address: any) => (
      <div key={address.addressHash} className="address_amount">
        {address.amounts.map(({ amount, currencyHash }: any) => {
          let symbol = currencyHash === token?.currencyHash ? token.symbol : config.cotiAsset.symbol;
          return (
            <Row key={address} className="expand_row">
              <Value value={amount} suffix={symbol} className="row_value" />
              <ValueController
                className="value_link"
                onClickValue={() => navigate(`/address/${address.addressHash}`)} 
                value={address.addressHash}
                copy="green"
                maxWidth
              />
            </Row>
          );
        })}
      </div>
    ))
  ), [addresses, navigate, token?.currencyHash, token.symbol]);
};

export default AmountPerAddress;