import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Input from 'components/Elements/Input/Input';
import ListItem from 'components/Elements/ListItem/ListItem';
import IconLabel, { Spinner } from 'components/Elements/IconLabel/IconLabel';
import { config } from 'config/config';
import { useOnClickOutside } from 'hooks';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import _, { uniqueId } from 'lodash';
import { resetSearchResults, search, selectExplorer } from 'features/explorer/explorerSlice';
import ValueController from 'components/Elements/ValueController/ValueController';
import './Search.scss';

type event = React.ChangeEvent<HTMLInputElement>

const Search = () => {
  const [searchKey, setSearchKey] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number|null>(null);
  const searchRef:any = useRef(null);
  useOnClickOutside(searchRef, () => showResults && setShowResults(false));
  const dispatch = useAppDispatch();
  const { searchResults, searchResultsFullfil, windowDimensions: {isDesktop} } = useAppSelector(selectExplorer);
  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line
  const debouncedTypingIndicationSearch = useCallback(_.debounce((key) => dispatch(search(key)), 500),[]);

  const hasResults:boolean = useMemo(()=> !!searchResults.tokens?.length || !!searchResults.addresses?.length || !!searchResults.transactions?.length, [
    searchResults.tokens?.length, 
    searchResults.addresses?.length, 
    searchResults.transactions?.length
  ]);
  
  useEffect(()=>{
    if(searchKey) {
      debouncedTypingIndicationSearch(searchKey);
      return
    }
    setSelectedRowIndex(null);
    dispatch(resetSearchResults());
  },[searchKey, debouncedTypingIndicationSearch, dispatch])

  useEffect(()=>{
    if(!searchResults) return;
    setShowResults(hasResults);
  },[hasResults, searchResults, location.pathname, dispatch]);
  
  useEffect(()=>{
    if(hasResults){
      setSearchKey("");
      setSelectedRowIndex(null);
      dispatch(resetSearchResults());
    }
    if(isDesktop && searchRef.current) {
      searchRef.current?.getElementsByTagName('input')[0].focus()
    }
    // eslint-disable-next-line
  },[location.pathname, dispatch]);

  const resetState = useCallback(()=>{
    setSearchKey("");
    setSelectedRowIndex(null);
    dispatch(resetSearchResults());
  },[dispatch]);

  const navigateTo = useCallback((element: any)=>{
    if(typeof element === "string") {
      navigate(`${element.length === 136 ? "/address" : "/transaction"}/${element}`)
      setShowResults(false);
      return
    }
    if(element.currencyHash) {
      navigate(`/token/${element.currencyHash}`)
      setShowResults(false);
      return
    }
    // TODO:: add node behavior when nodes will be supported;
  },[navigate]);

  const handleKeyDown = useCallback((event: any) => {
    if(!hasResults || !showResults) return
    const resultsArray = Object.values(searchResults).flat();
    if(event.key.toLowerCase() === 'enter') {
      if(resultsArray.length === 1) return navigateTo(resultsArray[0]);
      if(selectedRowIndex !== null) return navigateTo(resultsArray[selectedRowIndex])
      if(selectedRowIndex === null) return setSelectedRowIndex(0);
    };
    
    const startIndexSelected = selectedRowIndex === null ? -1 : selectedRowIndex;
    const itemSelector: any = searchRef.current?.querySelectorAll('li')
    const searchResultsSelector = searchRef.current?.querySelector('#search_results')

    const setActiveItem = (index:number) => {
      const {height} = itemSelector?.[index].getBoundingClientRect()
      const {offsetTop} = itemSelector?.[index]
      const scrollBy = offsetTop - searchResultsSelector.offsetHeight + (height * 1.8)
      if (searchResultsSelector.offsetHeight - offsetTop + height < 0) {
        searchResultsSelector.scrollTop = scrollBy
      }  else {
        searchResultsSelector.scrollTop = scrollBy
      }
      itemSelector?.[startIndexSelected]?.classList.remove('active')
      itemSelector?.[index]?.classList.add('active')
    }

    if(event.key.toLowerCase() === 'arrowdown') {
      if(selectedRowIndex === resultsArray.length-1) {event.preventDefault(); return }
      setActiveItem(startIndexSelected+1);
      setSelectedRowIndex(startIndexSelected+1);
      event.preventDefault();
    }

    if(event.key.toLowerCase() === 'arrowup') {
      if(selectedRowIndex === 0) {event.preventDefault(); return }
      setActiveItem(startIndexSelected-1);
      setSelectedRowIndex(startIndexSelected-1);
      event.preventDefault();
    }
  },[hasResults, searchResults, selectedRowIndex, showResults, navigateTo]);

  useEffect(()=>{
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  },[handleKeyDown]);

  return useMemo(() => {
    const onSearchChange = async (event: event) => {
      dispatch(resetSearchResults());
      setSearchKey(event.target.value);
    }
    
    return (
      <div ref={searchRef} className={`search_component ${(showResults || !hasResults) && searchKey && searchResultsFullfil ? 'active' : ''}`}>
        <Input placeholder={config.placeholders.search} value={searchKey} onChange={onSearchChange} onFocus={() => setShowResults(true)} autoFocus={isDesktop}/>
        <div className="app_icon search_icon"/>
        {searchKey &&<div className="app_icon close_icon" onClick={() => setSearchKey('')}/>}
        {searchKey && !searchResultsFullfil && <Spinner className="input" />}
        {(showResults || !hasResults) && searchKey && searchResultsFullfil  && <SearchResults resetState={resetState} hasResults={hasResults} selectedRowIndex={selectedRowIndex} results={searchResults} />}
      </div>
    );
  }, [showResults, hasResults, searchKey, searchResultsFullfil, isDesktop, resetState, selectedRowIndex, searchResults, dispatch])
}

export default Search;

const SearchResults = ({results, selectedRowIndex, hasResults, resetState}: any) => useMemo(() => (
  <div id="search_results" className={`search_results ${selectedRowIndex !== null ? 'is_highlight' : ""}`}>
    {results.tokens?.length ? <ResultsSection type='Tokens' results={results.tokens} resetState={resetState} /> : null}
    {results.addresses?.length ? <ResultsSection type='Addresses' results={results.addresses} resetState={resetState} /> : null}
    {results.transactions?.length ? <ResultsSection type='Transactions' results={results.transactions} resetState={resetState} /> : null}

    {!hasResults && (
      <ListItem className="option no_data">
        <IconLabel id="label" opt={{ label: config.placeholders.noSearchResults }} />
      </ListItem>
    )}
  </div>
), [results, selectedRowIndex, hasResults, resetState])

const ResultsSection = ({ results, type, resetState }: any) => {
  const navigate = useNavigate();
  const onClick = useCallback((result:any)=>{
    if(type === "Tokens") navigate(`/token/${result.currencyHash}`);
    if(type === "Addresses") navigate(`/address/${result}`);
    if(type === "Transactions") navigate(`/transaction/${result}`);
    resetState()
  },[navigate, type, resetState]);

  return useMemo(() => (
    <>
      <IconLabel id="label" opt={{label: type, className: 'results_title'}}/>
      <ul>
        {results.map((result: any) => (
          <ListItem key={uniqueId(type)} className='option' onClick={()=>onClick(result)}>
            {type === "Tokens" ? <IconLabel id="search_result_icon"
            opt={{currencyHash: result.currencyHash, currencySymbol: result.symbol, label: `${result.name} (${result.symbol}) - ${result.currencyHash}`}}/>
            : <ValueController value={result} maxWidth/>}
          </ListItem>
        ))}
      </ul>
    </>
  ), [results, onClick, type]);
}