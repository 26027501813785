import React, { useEffect, useMemo, useState } from 'react';
import Input from 'components/Elements/Input/Input';
import IconLabel from 'components/Elements/IconLabel/IconLabel';
import Value from 'components/Elements/Value/Value';
import { commaFormatted } from 'utilities';
import Row from 'components/Elements/Row/Row';
import Paginator from 'components/Elements/Paginator';
import 'components/Elements/Search/Search.scss';

const AddressTokens = ({tokens}:any) => {
    const [searchKey, setSearchKey] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pgSize = 3;

    const tokensMapped:any = useMemo(()=> 
        Object.keys(tokens).map((currencyHash:string) => ({...tokens[currencyHash], currencyHash}))
    ,[tokens]);
    
    const [filteredTokens, setFilteredTokens] = useState([]);

    const searchResults = (searchKey:string) => {
        const lowerSearchKey = searchKey.toLowerCase();
        const results:any = Object.values(tokensMapped).filter((token:any) => token.symbol.toLowerCase().includes(lowerSearchKey))
        setFilteredTokens(results)
    }
    
    useEffect(() => {
      searchKey ? searchResults(searchKey) : setFilteredTokens(tokensMapped);
    // eslint-disable-next-line
    }, [searchKey, tokensMapped]);
    
    return useMemo(() => (
        <div className="tokens">
            <SearchToken searchKey={searchKey} setSearchKey={setSearchKey}/>
            <div className={`tokens_balances ${filteredTokens?.length > 2 ? 'expand_rows_bg' : ''}`}>
                {filteredTokens.slice((currentPage - 1) * pgSize, currentPage * pgSize).map((token:any) =>
                <Token key={token.currencyHash} token={token}/>)}
            </div>
            {!!tokensMapped.length && <Paginator
                    currentPage={currentPage}
                    totalRecords={filteredTokens.length}
                    onFirstClick={() => setCurrentPage(1)}
                    onLastClick={(last:any) => setCurrentPage(last)}
                    onBackClick={() => setCurrentPage(currentPage - 1)}
                    onFwdClick={() => setCurrentPage(currentPage + 1)}
                    pgSize={pgSize}
                    onPageClick={(pageClicked:any) => setCurrentPage(pageClicked)}
                />}
        </div>
    ), [searchKey, filteredTokens, currentPage, tokensMapped]);
}

export default AddressTokens;

const SearchToken = ({searchKey, setSearchKey}:any) => {
    // TODO: add debounce
    return (
        <div className="search_component">
            <Input placeholder='Search for token' value={searchKey} onChange={(e:any) => setSearchKey(e.target.value)}/>
            <div className="app_icon search_icon"/>
        </div>
    )
}

const Token = ({token}:any) => {
    return (
        <Row className="token_balance">
            <IconLabel id="icon" opt={{currencyHash: token.currencyHash}} />
            <div className="values">
                {token.balance && <Value value={commaFormatted(token.balance, 8)} className="row_value"/>}
            </div>
        </Row>
    )
}