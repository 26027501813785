import Link from 'components/Elements/Link/Link';
import React from 'react';
import './FooterLinks.scss';

const FooterLinks = () => {
  const baseURL = process.env.REACT_APP_COTI_HOME
  return (
    <div className='footer_links'>
      <Link to={`${baseURL}/terms`} newWindow>Terms of use</Link>
      <Link to={`${baseURL}/privacy`} newWindow>Privacy Statement</Link>
    </div>
  )
}

export default FooterLinks;