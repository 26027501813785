import React, { useMemo } from 'react';
import './Tooltip.scss';

const Tooltip = ({text, custom, className}: any) => {
  
  return useMemo(() => (
    <div className={`tooltip_component ${className ?? ''} ${custom ? 'custom' : ''}`}>
      <p className='tooltip_text'>
        {text}
      </p>
      {custom ?? <div className='tooltip_icon' />}
    </div>
  ), [custom, className, text]);
}

export default Tooltip;