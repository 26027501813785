import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITransaction, ITransactionsBalance, ITransactionsState } from 'utilities/types';
import { RootState } from '../../app/store';
import { fetchAddressTransactions, fetchLast, fetchTokenTransactions, fetchTransaction } from './transactionsAPI';

export const initialState: ITransactionsState = {
  transactions: null,
  totalTransactions: null,
  lastTransactions: null,
  transaction: null,
  transactionsBalances: null,
  nativeBalance: null,
  connected: false,
  offset: 0,
  limit: 10,
  isLoading: false,
};

export type PaginationParams = {
  limit?: number;
  offset?: number;
}

export interface AddressTransactionsPayload extends PaginationParams {
  addressHash: string;
}

export interface TokenTransactionsPayload extends PaginationParams {
  currencyHash: string;
}

export const getLastTransacions = createAsyncThunk(
  'transactions/last',
  async (params:PaginationParams) => {
    const response = await fetchLast(params)
    return response.data;
  }
);

export const getTransaction = createAsyncThunk(
  'transaction',
  async (txHash: string) => {
    const response = await fetchTransaction(txHash);
    return response.data;
  }
);

export const getAddressTransactions = createAsyncThunk(
  'transaction/address',
  async ({addressHash, offset, limit}:AddressTransactionsPayload) => {
    const response = await fetchAddressTransactions(addressHash, limit ?? 10, offset ?? 0);
    return response.data;
  }
);

export const getTokenTransactions = createAsyncThunk(
  'transaction/token',
  async ({currencyHash, limit, offset}:TokenTransactionsPayload) => {
    const response = await fetchTokenTransactions(currencyHash, limit ?? 10, offset ?? 0);
    return response.data;
  }
);

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    connect: (state: ITransactionsState) => {

    },
    connected: (state: ITransactionsState) => {
      state.connected = true;
    },
    disconnect: (state: { connected: boolean; }) => {
      state.connected = false;
    },

    onGeneralTransactionEvent: (state: { offset:number, lastTransactions: any; transaction:any }, { payload }: PayloadAction<ITransaction>) => {
      if(state.transaction !== null && payload.hash === state.transaction.hash) {
        state.transaction = payload;
      }
      if(state.lastTransactions === null ) return
      const txIdx:number = state.lastTransactions.findIndex((t:ITransaction) => t.hash === payload.hash);
      let updatedProperties = state.lastTransactions;
      if (txIdx > -1) {
        updatedProperties.splice(txIdx,1, payload);
        state.lastTransactions = updatedProperties;
        return
      }
      if(state.offset === 0) {
        state.lastTransactions.pop();
        state.lastTransactions = [payload].concat([...state.lastTransactions]);
      }
    },
    onTransactionDetailsEvent: (state: { transaction:any }, { payload }: PayloadAction<ITransaction>) => {
      state.transaction = payload;
    },
    resetTransaction: (state:ITransactionsState) => {
      state.transaction = null
    },
    transactionSubscription: (state:ITransactionsState, { payload }: PayloadAction<any>) => {

    },
    addressSubscription: (state:ITransactionsState, { payload }: PayloadAction<any>) => {

    },
    tokenSubscription: (state:ITransactionsState, { payload }: PayloadAction<any>) => {

    },
    onTransactionsEvent: (state: ITransactionsState, { payload }: PayloadAction<ITransaction>) => {
      if(state.transactions === null) {
        state.transactions = [payload];
        return
      }
      const txIdx:number = state.transactions.findIndex((t:ITransaction) => t.hash === payload.hash);
      if (txIdx > -1) {
        let updatedProperties = state.transactions;
        updatedProperties.splice(txIdx,1, payload)
        state.transactions = updatedProperties;
        return
      }
      if(state.offset === 0) {
        if(state.transactions.length === 10) state.transactions.pop();
        state.transactions = [payload].concat([...state.transactions])
      }
    },
    onTransactionsCountEvent: (state: ITransactionsState, { payload }: PayloadAction<number>) => {
      state.totalTransactions = payload;
    },
    onAddressBalanceUpdate: (state: ITransactionsState, { payload }: PayloadAction<{nativeBalance: string; tokenData:ITransactionsBalance[]}> ) => {
      state.nativeBalance = payload.nativeBalance;
      state.transactionsBalances = payload.tokenData;
    },

    resetAddressTransactions: (state:ITransactionsState) => {
      state.transactionsBalances = null;
      state.totalTransactions = null;
      state.transactions = null;
      state.offset = 0;
      state.limit = 10;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLastTransacions.fulfilled, (state, {payload}) => {
        state.lastTransactions = payload.transactionsData
        state.isLoading = false;
      })
      .addCase(getLastTransacions.pending, (state, {meta}) => {
        state.isLoading = true;
        state.offset = meta.arg.offset ?? 0;
        state.totalTransactions = meta.arg.limit === 8 ? 0 : 200;
      })
      .addCase(getLastTransacions.rejected, (state) => {
        state.lastTransactions = null
        state.isLoading = false;
      })
      .addCase(getTransaction.fulfilled, (state, {payload}) => {
        state.transaction = payload.transactionData
      })
      .addCase(getTransaction.rejected, (state) => {
        state.transaction = null
      })
      .addCase(getAddressTransactions.pending, (state, {meta}) => {
        state.isLoading = true;
        state.offset = meta.arg.offset ?? 0;
      })
      .addCase(getAddressTransactions.fulfilled, (state, {payload}) => {
        state.transactionsBalances = payload.tokensData;
        state.nativeBalance = payload.nativeBalance;
        state.totalTransactions = payload.totalTransactions;
        state.transactions = payload.transactionsData;
        state.isLoading = false;
      })
      .addCase(getAddressTransactions.rejected, (state) => {
        state.transactionsBalances = null;
        state.nativeBalance = null;
        state.totalTransactions = null;
        state.transactions = null;
        state.isLoading = false;
      })
      .addCase(getTokenTransactions.fulfilled, (state, {payload}) => {
        state.totalTransactions = payload.totalTransactions;
        state.transactions = payload.transactionsData;
        state.isLoading = false;
      })
      .addCase(getTokenTransactions.pending, (state, {meta}) => {
        state.isLoading = true;
        state.offset = meta.arg.offset ?? 0
      })
      .addCase(getTokenTransactions.rejected, (state) => {
        state.isLoading = false;
      })
    }
});

export const {
  connect, disconnect, connected, transactionSubscription, resetAddressTransactions,
  onTransactionDetailsEvent, onGeneralTransactionEvent, resetTransaction, addressSubscription,
  onTransactionsEvent, onTransactionsCountEvent, tokenSubscription,
  onAddressBalanceUpdate
} = transactionsSlice.actions

export const selectTransactions = (state: RootState) => state.transactions;

export default transactionsSlice.reducer;