import { RootState } from 'app/store'
import { Dispatch } from 'redux'
import { ITransaction, ITransactionsBalance, SocketEvents } from 'utilities/types'
import { connected, onAddressBalanceUpdate, onGeneralTransactionEvent, onTransactionDetailsEvent, onTransactionsCountEvent, onTransactionsEvent } from 'features/transactions/transactionsSlice';
import { onCotiPriceEvent, onTransactionConfirmationEvent, onTreasuryTotalsEvent } from 'features/explorer/explorerSlice';
import { onNewTokenEvent, onTokenCirculatingSupplyUpdate, totalTokensUpdate } from 'features/tokens/tokensSlice';

interface SocketMiddlewareParams {
  dispatch: Dispatch
  getState: () => RootState
}

export default function socketMiddleware(socket: any) {
  return (params: SocketMiddlewareParams) => (next: any) => (action: any) => {
    const { dispatch } = params
    const { type, payload } = action

    switch (type) {
      // Connect to the socket 
      case 'transactions/connect': {
        console.log('connect');
        
        socket.connect();
        
        socket.on('connect', ()=> {
          dispatch(connected());
        });

        socket.on('connect_error', (error: any)=> console.log('socket connection error: ', error));
        
        break
      }

      case 'transactions/connected': {
        console.log('connected');
        socket.on(SocketEvents.GeneralTransactionsNotification, (data: ITransaction) => {
          dispatch(onGeneralTransactionEvent(data))
        });
        break;
      }

      case 'explorer/subscribe': {        
        socket.emit('subscribe', payload);
        break;
      }

      case 'transactions/transactionSubscription': {

        socket.emit('subscribe', payload);
        
        socket.on(SocketEvents.TransactionDetails, (data: ITransaction) => {
          dispatch(onTransactionDetailsEvent(data))
        });

        break;
      }

      case 'explorer/explorerEventsSubscription': {
          socket.emit('subscribe', {treasuryTotals: true, transactionConfirmationUpdates: true, cotiPrice: true});
          
          socket.on(SocketEvents.TreasuryTotalsUpdates, (data: any)=> {
            dispatch(onTreasuryTotalsEvent(data.totalCotiInPool));
          })
          
          socket.on(SocketEvents.TransactionConfirmationUpdate, (data: any)=> {
            dispatch(onTransactionConfirmationEvent(data.average));
          })
          
          socket.on(SocketEvents.CotiPrice, (data: any)=> {
            dispatch(onCotiPriceEvent(data.price));
          })

          break;
      }

      case 'transactions/addressSubscription': {
        
        socket.emit('subscribe', payload);
        
        socket.on(SocketEvents.AddressTransactionsNotification, (data: ITransaction) => {
          dispatch(onTransactionsEvent(data));
        });

        socket.on(SocketEvents.AddressTransactionsTotalNotification, (data: number) => {
          dispatch(onTransactionsCountEvent(data));
        });

        socket.on(SocketEvents.AddressBalanceUpdate, ({nativeBalance, tokenData}: {nativeBalance: string; tokenData: ITransactionsBalance[]}) => {
          dispatch(onAddressBalanceUpdate({nativeBalance, tokenData}));
        });

        break;
      }
      case 'transactions/tokenSubscription': {

        const { tokenHash, tokenAllEvents } = payload;
        
        socket.emit('subscribe', {tokenHash});
        
        socket.on(SocketEvents.TokenCirculatingSupplyUpdate, (data: any) => {
          dispatch(onTokenCirculatingSupplyUpdate(data));
        });
        
        if(tokenAllEvents) {
          socket.on(SocketEvents.TokenTransactionsNotification, (data: ITransaction) => {
            dispatch(onTransactionsEvent(data));
          });

          socket.on(SocketEvents.TokenTransactionsTotal, (data: number) => {
            dispatch(onTransactionsCountEvent(data));
          });
        }

        break;
      }
  
      case 'explorer/unsubscribe': {
        socket.emit('unsubscribe', payload);
        break;
      }

      case 'tokens/tokensSubscription': {
        socket.on(SocketEvents.NewTokens, (data: any)=> {
          dispatch(onNewTokenEvent(data));
        })

        socket.on(SocketEvents.TokensTotal, (data: any)=> {
          dispatch(totalTokensUpdate(data.count));
        })
        break;
      }

      // Disconnect from the socket 
      case 'transactions/disconnect': {
        console.log('disconnect');
        
        socket.disconnect()

        break
      }

    }

    return next(action)
  }
}
