import React, { useMemo } from 'react';
import { config } from 'config/config';
import Label from 'components/Elements/Label/Label';
import Row from 'components/Elements/Row/Row';
import ValueController from 'components/Elements/ValueController/ValueController';
import { useNavigate } from 'react-router';

const TransferDetails = ({currencyHash, to}: any) => {
  const navigate = useNavigate();
  return useMemo(() => {
    const { tokenHash } = config.transactionDetails;
    const isCoti = currencyHash === config.cotiAsset.currencyHash
    return (
      <>
        <Row className='tokenHash'>
          <Label label={tokenHash.label} tooltip={tokenHash.tooltip} />
          <ValueController
            value={currencyHash}
            onClickValue={() => isCoti ? null : navigate(`/token/${currencyHash}`)}
            className={`${isCoti ? '' : 'value_link'}`}
            copy="green"
            maxWidth
          />
        </Row>

        <Row className='to'>
          <Label label={config.transactionDetails.to.label} tooltip={config.transactionDetails.to.tooltip}/>
          <ValueController value={to} copy="green" maxWidth onClickValue={() => navigate(`/address/${to}`)} className="value_link"/>
        </Row>
      </>
    );
  }, [currencyHash, to, navigate]);
}

export default TransferDetails;