import React, { useMemo } from 'react';
import Value from 'components/Elements/Value/Value';
import Stat from 'components/Elements/Stat/Stat';
import { config } from 'config/config';
import { selectExplorer } from 'features/explorer/explorerSlice';
import { useAppSelector } from 'app/hooks';
import { absAmount, commaFormatted, useCotiToUsd } from 'utilities';
import './MainStats.scss';


const MainStats = () => {
    const { cotiPrice, TreasuryLockedValue, confirmationTime } = config.mainStats
    const state = useAppSelector(selectExplorer);
    const cotiToUsd = useCotiToUsd(); 

    return useMemo(() => (
      <div className='main_stats'>
        <Stat
          className='cotiPrice'
          tooltip={cotiPrice.tooltip}
          label={cotiPrice.label}
          prefix={cotiPrice.prefix}
          value={state.price ? commaFormatted(state.price, 4) : "N/A"}
        />

        <Stat
          className='TreasuryLockedValue'
          tooltip={TreasuryLockedValue.tooltip}
          label={TreasuryLockedValue.label}
          value={state.treasuryTVL ? commaFormatted(state.treasuryTVL, 0) : "N/A"}
        >
          <div className="secondary_value">
          (<Value prefix='$' value={commaFormatted(cotiToUsd(state.treasuryTVL), 2)}/>)
          </div>
        </Stat>

        <Stat
          className='confirmationTime'
          tooltip={confirmationTime.tooltip}
          label={confirmationTime.label}
          value={typeof state.txConfirmationTime === "string" ? commaFormatted(absAmount(state.txConfirmationTime), 0) : "N/A"}
          suffix={confirmationTime.suffix}
        />
      </div>
    // eslint-disable-next-line
  ), [state, cotiToUsd]);
}

export default MainStats;