import axios, { AxiosInstance } from 'axios';

export const {
    REACT_APP_EXPLORER_API: EXPLORER_API,
    REACT_APP_EXPLORER_WS: EXPLORER_WS,
    REACT_APP_EXPLORER_STORAGE: EXPLORER_STORAGE,
    REACT_APP_EXPLORER_STORAGE_NODE: EXPLORER_STORAGE_NODE,
    REACT_APP_TREASURY_API: TREASURY_API 
} = process.env;

const headers = {
    "Content-type": "application/json"
}

const explorerApi: AxiosInstance = axios.create({
    baseURL: EXPLORER_API,
    headers
})

const treasuryApi: AxiosInstance = axios.create({
    baseURL: TREASURY_API,
    headers
})

const explorerStorage: AxiosInstance = axios.create({
    baseURL: EXPLORER_STORAGE,
    headers
})

const explorerStorageNode: AxiosInstance = axios.create({
    baseURL: EXPLORER_STORAGE_NODE,
    headers
})

const api = {
    getTokenIcon: (currencyHash: string) => explorerStorage.get(`${currencyHash}`),
    getPrice: () => explorerApi.post(`/statistic/coti-price`),
    getTreasuryTVL: () => treasuryApi.get(`/get-total`),
    getTxConfirmationTime: () => explorerApi.post(`/statistic/transaction-confirmation-time`),
    lastTransactions: (limit:number, offset: number) => explorerApi.post(`/transaction/last-transactions`, {limit, offset}),
    getTransactionByHash: (txHash: string) => explorerApi.post(`/transaction`, {transactionHash: txHash}),
    getTransactionsByAddress: (address: string, limit:number, offset: number) => explorerApi.post(`/transaction/address`,{address, limit, offset}),
    getTransactionsByToken: (currencyHash: string, limit:number, offset: number) => explorerApi.post(`/transaction/token`, {currencyHash, limit, offset}),
    getTokens: (limit:number, offset: number) => explorerApi.post(`/token`, {limit, offset}),
    getTokensByCurrencyHash: (currencyHash: string) => explorerApi.post(`/token/get-info`, {currencyHash}),
    getTokenBySymbol: (symbol: string) => explorerApi.post(`/token/symbol`, {symbol}),
    search: (criteria: string) => explorerApi.post(`/search`, {criteria}),
    getNodeInfoByHash: (nodeHash: string) => explorerApi.post(`/node/get-info`, {nodeHash}),
    getNodeIcon: (nodeHash: string) => explorerStorageNode.get(`/${nodeHash}.svg`),
}

export default api;