import React, { useMemo } from 'react';
import { useToken } from 'components/Elements/IconLabel/IconLabel';
import Value from 'components/Elements/Value/Value';

const AmountSymbol = ({ value, currencyHash, className }: any) => {
  const [ token ] = useToken(currencyHash);
  return useMemo(() => (
          <Value
            value={value}
            className={`amount_symbol_component ${className ?? ''}`}
            suffix={token.symbol}
          />
  ), [className, token.symbol, value])
}

export default AmountSymbol;