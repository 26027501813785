import React, { useMemo } from 'react';
import styles from './Layout.module.scss';

type Props = {
  children: any,
}

const Layout = ({children}:Props) => {
  return useMemo(() => (
    <div className={styles.layout_component}>
      {children}
    </div>
  ), [children])
}

export default Layout;