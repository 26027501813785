import api from 'api/api';
import { PaginationParams } from './tokensSlice';

export function fetchToken(currencyHash:string) {
  return api.getTokensByCurrencyHash(currencyHash);
}

export function fetchTokenBySymbol(currencySymbol:string) {
  return api.getTokenBySymbol(currencySymbol);
}

export function fetchTokenIcon(currencyHash:string) {
  return api.getTokenIcon(`${currencyHash}.svg`);
}

export function fetchTokens({limit, offset}:PaginationParams) {
  return api.getTokens(limit ?? 10 ,offset ?? 0);
}