import React from 'react';
import { Spinner } from '../IconLabel/IconLabel';
import './Paginator.scss';

const Paginator = ({
  currentPage,
  totalRecords,
  onBackClick,
  onFwdClick,
  pgSize,
  onLastClick,
  onFirstClick,
  itemsType,
  isLoading
}: any) => {
  if (!totalRecords || totalRecords <= pgSize) {
    return null;
  }
  const lastPage = Math.ceil(totalRecords / pgSize);

  return (
    <div className='paginator'>
      <span className="page_of_total">
      {isLoading && <Spinner className="spinner-stat" title="Pending" />}
      {itemsType && <span className="item_type">{itemsType}</span>}<b>{((currentPage - 1) * pgSize)+1}-{currentPage * pgSize > totalRecords ? totalRecords : currentPage * pgSize}</b>&nbsp;of {totalRecords}
      </span>
      <button disabled={currentPage === 1} onClick={onFirstClick}>
        <div className="app_icon first"/>
      </button>
      <button disabled={currentPage === 1} onClick={onBackClick}>
        <div className="app_icon previous"/>
      </button>
      <button disabled={currentPage === lastPage} onClick={onFwdClick}>
        <div className="app_icon next"/>
      </button>
      <button disabled={currentPage === lastPage} onClick={() => onLastClick(lastPage)}>
        <div className="app_icon last"/>
      </button>
    </div>
  );
};

export default Paginator;