import React, { useMemo } from 'react';
import Value from 'components/Elements/Value/Value';
import Label from 'components/Elements/Label/Label';
import'./Stat.scss';

const Stat = ({ tooltip, label, value, suffix, prefix, children, className }: any) => {
  return useMemo(() => (
    <div className={`stat_component ${className ?? ''}`}>
      <Label
        tooltip={tooltip}
        label={label}
      />

    <div className="values">
      <Value
        prefix={prefix}
        value={value}
        suffix={suffix}
      />
      {children}
    </div>
      
    </div>
  ), [className, tooltip, label, prefix, value, suffix, children])
}

export default Stat;
