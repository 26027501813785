import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from 'hooks';
import { useAppSelector } from "app/hooks";
import { selectExplorer } from "features/explorer/explorerSlice";

const ModalLayout = ({onClose,children, modalDetails}: any) => {
  const [innerModalPosition, setInnerModalPosition] = useState({})
  const {windowDimensions:{innerWidth,innerHeight}} = useAppSelector(selectExplorer);
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, onClose);
  useEffect(() => {
    const setModalPosition = ({height, width} : {height: number, width: number}) => {
      setInnerModalPosition({
        left: innerWidth - modalDetails.clientX < 400 ? (modalDetails.clientX-400 > 0 ? modalDetails.clientX-width : 0) : modalDetails.clientX,
        top: innerHeight - modalDetails.clientY < 400 ? modalDetails.clientY-height : modalDetails.clientY,
      })
    }
    if (containerRef.current) {
      setModalPosition({height: containerRef.current.clientHeight, width: containerRef.current.clientWidth})
    }
  }, [containerRef, innerHeight, innerWidth, modalDetails.clientX, modalDetails.clientY]);

  useEffect(()=>{
    document.body.style.paddingRight = "4px"
    document.body.style.overflow = "hidden"
    return () => {document.body.style.overflow = "unset"; document.body.style.paddingRight = "0px";}
  },[]);

  return <div className="modal-layout">
    <div className="modal-inner" style={innerModalPosition} ref={containerRef}>
      <div className="app_icon close-modal" onClick={onClose}/>
      {children}
    </div>
  </div>
}

export default ModalLayout;

export const Eye = ({onClick}: any) => <div className="app_icon eye_icon" onClick={onClick}></div>