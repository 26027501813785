import React, { useCallback, useMemo, useState } from 'react';
import { config } from 'config/config';
import styles from './Menu.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectExplorer } from 'features/explorer/explorerSlice';
import { NetworkDropdown } from 'components/Elements/Dropdown/Dropdown';

const Menu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const active = location.pathname;
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const {windowDimensions: {isMobile}} = useAppSelector(selectExplorer);

    const onClickHandler = useCallback((path:string) => {
        navigate(path)
        if (active !== path) {
            setIsMenuOpen(false)
        }
    },[active, navigate, setIsMenuOpen])

    return useMemo(() => (
        <div className={`${styles.navbar_component} ${isMenuOpen ? styles.open : ''}`}>
            {isMobile && <MenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
            <ul className={styles.navbar_options}>
                {Object.values(config.menu).map(navLink => {
                    return (
                        <NavItem
                            key={navLink.label}
                            className={`${styles.nav_link} ${active === navLink.path ? styles.active : ''}`}
                            label={navLink.label}
                            image={navLink.image}
                            onClick={() => onClickHandler(navLink.path)}
                            isActive={active === navLink.path}
                        />
                    )
                })}
                {isMobile && <NetworkDropdown className={`capitalize ${styles.select_network}`}/>}
            </ul>
        </div>
  ), [active, isMenuOpen, isMobile, onClickHandler])
}

export default Menu;

const NavItem = ({label, onClick, isActive, className}: any) => useMemo(() => (
    <li className={`${className ?? ''} ${isActive ? 'active' : ''}`} onClick={onClick}>
        <div className={styles.nav_item}>
            <div className={`${styles.nav_icon} ${styles[label.toLowerCase()]}`} />
            {label}
        </div>
        <div className={styles.underline} />
    </li>
), [className, isActive, label, onClick])

const MenuButton = ({ isMenuOpen, setIsMenuOpen }: any) => {
  return (
    <button onClick={() => setIsMenuOpen(!isMenuOpen)} className={styles.menu_button}>
      <span/><span/><span/>
    </button>
  )
}