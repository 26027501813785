import React from 'react';
import './Chip.scss'

const Chip = ({label, className}:any) => {
  return (
    <span className={`chip ${className ?? ''}`}>
      {label}
    </span>
  )
}

export default Chip;