import React, { useMemo } from 'react';
import './Value.scss';

const Value = ({ value, prefix, suffix, className }: any) => {
  const isValueNA = value === 'N/A'
  return useMemo(() => (
    <div className={`value_component ${className ?? ''}`}>
        {!isValueNA && prefix && <span className='prefix'>{prefix}</span>}
        {value}
        {!isValueNA && suffix && <span className='suffix'>{suffix}</span>}
    </div>
  ), [className, isValueNA, prefix, suffix, value])
}

export default Value;
