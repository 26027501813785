import Label from 'components/Elements/Label/Label';
import Paginator from 'components/Elements/Paginator';
import RowHeader from 'components/Elements/Table/RowHeader';
import Row from 'components/Elements/Table/Tokens/Row';
import { config } from 'config/config';
import { selectExplorer, subscribe, unsubscribe } from 'features/explorer/explorerSlice';
import { selectTransactions, tokenSubscription } from 'features/transactions/transactionsSlice';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getTokens, selectTokens, tokensSubscription } from './tokensSlice';

export function Tokens() {
  const dispatch = useAppDispatch();
  const { tokens, fulfilled, offset, totalTokens, isLoading } = useAppSelector(selectTokens);
  const {windowDimensions: {isMobile, isDesktop}} = useAppSelector(selectExplorer);
  const { connected } = useAppSelector(selectTransactions);
  const [ currentPage, setCurrentPage ] =  useState(1);
  const pgSize = 10;
  
  useEffect(() => {
    dispatch(getTokens({limit: pgSize, offset: 0}));
  }, [dispatch]);

  const newOffset: number = useMemo(()=>currentPage === 1 ? 0 : (currentPage - 1) * pgSize,[currentPage]);

  const tokensHashes = useMemo(()=> fulfilled ? Object.keys(tokens).concat(Array(10).fill("")).splice(0,10) : Array(10).fill("") ,[fulfilled, tokens]);

  useEffect(()=>{
    if(offset === newOffset) return;
    dispatch(getTokens({limit: pgSize, offset: newOffset}));
    // eslint-disable-next-line
  },[newOffset]);

  useEffect(() => {
    if (!connected) return;
    dispatch(subscribe({tokens: true}));
    dispatch(tokensSubscription())
    return () => {
      dispatch(unsubscribe({tokens: true}));
    }
  }, [connected, dispatch]);

  useEffect(()=>{
    if(fulfilled) {
      tokensHashes.forEach(tokenHash => tokenHash !== "" && dispatch(tokenSubscription({tokenHash})) )
    }
    return () => {
      tokensHashes.forEach(tokenHash => tokenHash !== "" && dispatch(unsubscribe({tokenHash})) )
    }
    // eslint-disable-next-line
  },[...tokensHashes, fulfilled, dispatch]);
 
  const TokensPaginator = useCallback(() => (
      <Paginator
          currentPage={currentPage}
          totalRecords={totalTokens}
          pgSize={pgSize}
          onLastClick={() => totalTokens && setCurrentPage(Math.ceil(totalTokens / pgSize))}
          onFirstClick={() => setCurrentPage(1)}
          onBackClick={() => setCurrentPage(currentPage - 1)}
          onFwdClick={() => setCurrentPage(currentPage + 1)}
          isLoading={isLoading}
        />
    ),[currentPage, totalTokens, isLoading],
  )

  return useMemo(()=> (
    <>
    <div className="tokens">
      <div className="table multi_pages">
        <div className="headline_wrapper">
          <Label label="Tokens" className="row_title" />
          {!isMobile && <TokensPaginator/>}
        </div>
        {isDesktop && <RowHeader columns={config.tableFields.tokens} className="tokens_row" />}
        {fulfilled && Object.values(tokens).map((token:any) => (
          <Row key={token.currencyHash} token={token} />
        ))}
      </div>
    </div>
    <TokensPaginator/>
  </>
  ),[isMobile, TokensPaginator, isDesktop, fulfilled, tokens]);
}