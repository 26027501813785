import React from 'react';
import cotiLogo from 'images/logos/coti_logo.svg';
import FooterLinks from './FooterLinks/FooterLinks';
import PoweredBy from './PoweredBy/PoweredBy';
import styles from './Footer.module.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <div className={styles.footer}>
      <div className={styles.footer_wrapper}>
        <div className={styles.footer_left}>
          <div className={styles.copyright}>
            <span>© {currentYear} all rights reserved to</span>
            <img className={styles.logo} src={cotiLogo} alt='COTI'/>
          </div>
          <FooterLinks/>
        </div>
        <PoweredBy />
      </div>
    </div>
  )
}

export default Footer;