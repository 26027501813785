import React, { useMemo } from 'react';
import './CircleFill.scss';

const CircleFill = ({value}:any) => {
  const max = 138
  const perecentageToCircle = max/100*(value > 100 ? 100 : value)
  return useMemo(() => (
    <div className="circle_fill">
      <svg className="circle" width="48px" height="48px">
        <circle className="bg" cx="24" cy="24" r="22"/>
          <circle className="fill" cx="24" cy="24" r="22" style={{ strokeDasharray: `${perecentageToCircle} ${max}` }}/>
      </svg>
      <div className='value'>
        <p>{Math.floor(value)}</p>
      </div>
  </div>	
  ), [perecentageToCircle, value])
}

export default CircleFill;
