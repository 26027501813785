export const networks = ['mainnet', 'testnet'];

export const BaseTransactionName = {
    INPUT: "IBT",
    PAYMENT_INPUT: "PIBT",
    FULL_NODE_FEE: "FFBT",
    NETWORK_FEE: "NFBT",
    ROLLING_RESERVE: "RRBT",
    RECEIVER: "RBT",
    TOKEN_GENERATION_FEE: "TGBT",
    TOKEN_MINT: "TMBT"
}

export const fields = {
    name: {
        label: 'Name:',
        tooltip: 'Token name.'
    },
    symbol: {
        label: 'Symbol:',
        tooltip: 'Token symbol.'
    },
    originatorHash: {
        label: 'Issuer Hash:',
        tooltip: 'Unique token issuer identifier.'
    },
    holders: {
        label: 'Holders:',
        tooltip: 'Number of addresses holding the token.'
    },
    maxSupply: {
        label: 'Max Supply:',
        tooltip: 'The max amount of tokens that can be minted by the issuer.'
    },
    circulatingSupply: {
        label: 'Circulating Supply:',
        tooltip: "Amount of minted tokens circulating the market."
    },
    trustChainSupply: {
        label: 'Trustchain Supply:',
        tooltip: 'Amount of tokens in circulation on COTI’s Trustchian.'
    },
    scale: {
        label: 'Scale',
        tooltip: 'Number of supported digits to the right of the decimal point of token amount (0.00000001).'
    },
    websiteURL: {
        label: 'Official Website:',
        tooltip: 'Token issuer website URL.'
    },
    socialMedia: {
        label: 'Social Media:',
        tooltip: 'Links issuer social media channels.'
    },
    hash: {
        label: 'Hash:',
        tooltip: 'Unique Trustchain address identifier.'
    },
    balance: {
        label: 'Balance:',
        tooltip: 'Address native coin balance.'
    },
    tokenValue: {
        label: 'Token Value:',
        tooltip: 'Total value of all tokens on address balance.'
    },
    tokens: {
        label: 'Tokens:',
        tooltip: 'A list of tokens held on address balance.'
    },
    nameSymbol: {
        label:'Name(Symbol)',
        tooltip: 'Token name and symbol.'
    },
    txHash: {
        label:'Tx Hash:',
        tooltip: 'Unique transaction identifier.'
    },
    attachmentTime: {
        label:'Attachment Time:',
        tooltip: 'Attachment to DAG time.'
    },
    type: {
        label:'Type:',
        tooltip: 'Transaction type.'
    },
    trustScore: {
        label:'TS:',
        tooltip: 'To receive transaction confirmation, Trust Score must reach a value of 101 or higher.'
    },
    from: {
        label:'From:',
        tooltip: 'Sending address.'
    },
    to: {
        label:'To:',
        tooltip: 'Receiving address.'
    },
    amount: {
        label:'Amount:',
        tooltip: 'Transaction amount.'
    },
    fees: {
        label:'Fees:',
        tooltip: 'Total fees amount.'
    },
    networkFees: {
        label:'Network Fees:',
        tooltip: 'Fees collected by the network.'
    },
    fullNodeFees: {
        label:'Full Node Fee:',
        tooltip: 'Fee collected by the full node operator.'
    },
    processingNode: {
        label:'Processing Node:',
        tooltip: 'Full node responsible for transaction processing.'
    },
    confirmationTime: {
        label:'Confirmation Time:',
        tooltip: 'Time of transaction confirmation.'
    },
    tokenHash: {
        label:'Token Hash:',
        tooltip: 'Unique token identifier.'
    },
    token: {
        label:'Token:',
        tooltip: 'Transaction token'
    },
    currencyName: {
        label: 'Name:',
        tooltip: 'Token name.',
    },
    totalSupply: {
        label: 'Total Supply:',
        tooltip: 'The max amount of tokens that can be minted by the issuer.'
    },
    description: {
        label:'Description:',
        tooltip: 'Transaction description.'
    },
    status: { 
        label: 'Status:',
        tooltip: 'Current transaction status.',
    },
    time: {
        label:'Time:',
        tooltip: 'Elapsed time since transaction attachment.'
    },
    createTime: {
        label:'Time:',
        tooltip: 'Token creation time.'
    },
}

export const config = {
    cotiAsset: {
        name: 'Currency Of The Internet',
        symbol: 'COTI',
        currencyHash: 'ae2b227ab7e614b8734be1f03d1532e66bf6caf76accc02ca4da6e28',
        scale: 8
    },
    menu: {
        home: {
            label: 'Home',
            image:'home',
            path: '/'
        },
        transactions: {
            label: 'Transactions',
            image:'transactions',
            path: '/transactions'
        },
        tokens: {
            label: 'Tokens',
            image:'tokens',
            path: '/tokens'
        }
    },
    mainStats: {
        cotiPrice: {
            tooltip: 'Current COTI market price.',
            label: 'Price (COTI)',
            prefix: '$'
        },
        TreasuryLockedValue: {
            tooltip: 'Total amount of COTI deposited in Treasury',
            label: 'Total Value Locked (TVL)'
        },
        confirmationTime: {
            tooltip: 'Network transaction average confirmation time.',
            label: 'AVG Confirmation Time (24h)',
            suffix: ' Seconds'
        }
    },
    txStatuses: {
        pending: 'Attached to DAG',
        confirmed: 'Confirmed'
    },
    placeholders: {
        search: 'Search by: Transaction Hash, Address, Token Symbol, Token Name, Token Hash',
        noSearchResults: 'No results found',
    },
    tableFields: {
        transactions: {
            desktop: {
                trustScore: fields.trustScore,
                hash: fields.txHash,
                from: fields.from,
                to: fields.to,
                status: fields.status,
                token: fields.token,
                type: fields.type,
                amount: fields.amount,
                time: fields.time,
            },
            tablet: {
                trustScore: fields.trustScore,
                hash: fields.txHash,
                status: fields.status,
                type: fields.type,
                amount: fields.amount,
                time: fields.time,
            },
            portraitTablet: {
                trustScore: fields.trustScore,
                status: fields.status,
                type: fields.type,
                amount: fields.amount,
                time: fields.time,
            }
        },
        tokens: {
            nameSymbol: fields.nameSymbol,
            maxSupply: fields.maxSupply,
            circulatingSupply: fields.circulatingSupply,
            trustChainSupply: fields.trustChainSupply,
            createTime: fields.createTime,
            currencyHash: fields.tokenHash,
        }
    },
    transactionDetails: {
        hash: fields.txHash,
        attachmentTime: fields.attachmentTime,
        type: fields.type,
        trustScore: fields.trustScore,
        from: fields.from,
        to: fields.to,
        amount: fields.amount,
        fees: fields.fees,
        networkFees: fields.networkFees,
        fullNodeFees: fields.fullNodeFees,
        processingNode: fields.processingNode,
        confirmationTime: fields.confirmationTime,
        tokenHash: fields.tokenHash,
        token: fields.token,
        currencyName: fields.currencyName,
        currencyHash: fields.tokenHash,
        totalSupply: fields.totalSupply,
        scale: fields.scale,
        originatorHash: fields.originatorHash,
        description: fields.description,
        status: fields.status,
    },
    addressDetails: {
        hash: fields.hash,
        amount: fields.balance,
        tokenValue: fields.tokenValue,
        tokens: fields.tokens,
    },
    tokenDetails: {
        name: fields.name,
        symbol: fields.symbol,
        originatorHash: fields.originatorHash,
        currencyHash: fields.tokenHash,
        holders: fields.holders,
        maxSupply: fields.maxSupply,
        circulatingSupply: fields.circulatingSupply,
        trustChainSupply: fields.trustChainSupply,
        scale: fields.scale,
        websiteURL: fields.websiteURL,
        socialMedia: fields.socialMedia,
        createTime: fields.createTime,
    }
}

type ITxType = {
    type: string;
    title: string;
}

interface ITransactionTypes {
    Initial: ITxType;
    TokenGeneration: ITxType;
    TokenMinting: ITxType;
    Transfer: ITxType;
    ZeroSpend: ITxType;
    EventHardFork: ITxType;
}

export const transactionTypes: ITransactionTypes | any  = {
    Initial:{
        type: 'Initial',
        title: 'Initial'
    },
    TokenGeneration:{
        type: 'TokenGeneration',
        title: 'Generate'
    },
    TokenMinting:{
        type: 'TokenMinting',
        title: 'Mint'
    },
    Transfer:{
        type: 'Transfer',
        title: 'Transfer'
    },
    ZeroSpend:{
        type: 'ZeroSpend',
        title: 'Zero spend'
    },
    EventHardFork:{
        type: 'EventHardFork',
        title: 'HardFork'
    }
}