import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ListItem from 'components/Elements/ListItem/ListItem';
import './Dropdown.scss';
import { networks } from 'config/config';

type Props = {
  options: any;
  selected?: string;
  className?: string;
  onClick: (idx: number) => void;
}

const Dropdown = ({options, selected, className, onClick}: Props) => useMemo(() => {
  return (
    <div className={`dropdown_component ${className ?? ''}`}>
      <ListItem className='option selected'>
        {selected ?? options[0]}
      </ListItem>
      <div className='arrow_down' />
      <ul className='dropdown_options'>
        {options.map((option: any, idx: number) => (
          <ListItem key={idx} className='option' onClick={() => onClick(idx)}>
            {option}
          </ListItem>
        ))}
      </ul>
    </div>
  );
}, [className, onClick, options, selected])

export default Dropdown;

export const NetworkDropdown = ({className}:{className?: string}) => {
  const selectedNetworkIndex:number = Number(process.env.REACT_APP_SELECTED_NETWORK) ?? 1;
  const [selectedNetwork, setSelectedNetwork] = useState<string>(networks[selectedNetworkIndex]);

  const redirectToNetworkExplorer = useCallback(():void => {
    if(selectedNetwork === 'mainnet') {
      window.location.href = `${process.env.REACT_APP_EXPLORER_MAINNET}`
      return
    }
    window.location.href = `${process.env.REACT_APP_EXPLORER_TESTNET}`;
  },[selectedNetwork]);

  useEffect(()=>{
    if(networks[selectedNetworkIndex] === selectedNetwork) return;
    redirectToNetworkExplorer();
  },[redirectToNetworkExplorer, selectedNetwork, selectedNetworkIndex]);

  return <Dropdown
    className={className}
    options={networks}
    selected={selectedNetwork}
    onClick={(idx: number) => setSelectedNetwork(networks[idx])}
  />
}