import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import ModalLayout, { Eye } from 'components/Elements/Modal/Modal';
import ValueController from 'components/Elements/ValueController/ValueController';
import { MapAddresses } from '.';
import './Addresses.scss';

export const Address = ({ onClickValue, addresses=[], maxWidth, addressesLength, eye }: any) => {
    const navigate = useNavigate();
    const isMultiple = addressesLength || addresses?.length > 1
    const addressNA = addresses[0] === 'N/A'
    const [ modalDetails, setModalDetails ] = useState<null|any>(null);
  
    const onClickEye = (event:any)=> {
      event.stopPropagation()
      setModalDetails({clientX: event.clientX, clientY: event.clientY});
    }
  
    const onClose = (event:any)=> {
      event.stopPropagation()
      setModalDetails(null);
    }

    return useMemo(() => (
      <div className={`address_component ${isMultiple ? 'multiple_addresses' : ''}`}>
        {isMultiple ? 
          <span>
            Multiple Addresses
          </span>
          : <ValueController
              className={`${!addressNA ? 'value_link' : ''}`}
              value={addresses[0]}
              maxWidth={maxWidth}
              onClickValue={() => addressNA ? null : onClickValue ? onClickValue() : navigate(`/address/${addresses[0]}`)}
            />
        }
        {!addressNA && (eye ? <Eye onClick={onClickEye}/> : `(${addressesLength ?? addresses.length})`)}

      {modalDetails && (
        <ModalLayout modalDetails={modalDetails} onClose={onClose}>
          <MapAddresses addresses={addresses} onClickValue={onClickValue}/>
        </ModalLayout>
      )}
      </div>
    ), [isMultiple, addressNA, addresses, maxWidth, eye, addressesLength, modalDetails, onClickValue, navigate])
  }