import React, { useMemo } from 'react';
import cotiExplorerLogo from 'images/logos/coti_explorer.svg';
import styles from './Logo.module.scss';
import { useNavigate } from 'react-router-dom';

const Logo = () => {
  const navigate = useNavigate();
  
  return useMemo(() => (
    <div className={styles.logo_component}>
      <img className={styles.logo} src={cotiExplorerLogo} alt='COTI Explorer' onClick={()=>navigate("/")}/>
    </div>
  ), [navigate])
}

export default Logo;