import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Address } from 'components/Elements/Addresses';
import CircleFill from 'components/Elements/CircleFill/CircleFill';
import IconLabel from 'components/Elements/IconLabel/IconLabel';
import Status from 'components/Elements/Status/Status';
import Value from 'components/Elements/Value/Value';
import Column from 'components/Elements/Table/Column';
import { BaseTransactionName, config, transactionTypes } from 'config/config';
import { useNavigate } from 'react-router-dom';
import { IBaseTransaction } from 'utilities/types';
import { commaFormatted, getBaseTxByName, getTxAmount, getTxCurrencyHash, getTxCurrencySymbol, getUniqueAddrMap, timestampToDisplay, valueMaxLength } from 'utilities';
import { useAppSelector } from 'app/hooks';
import { selectExplorer } from 'features/explorer/explorerSlice';
import Expand from 'components/Elements/Expand/Expand';
import {Row as ExpandRow} from 'components/Elements/Row/Row';
import Label from 'components/Elements/Label/Label';
import moment from 'moment';
import './Row.scss'
import Tooltip from 'components/Elements/Tooltip/Tooltip';
import ValueController from 'components/Elements/ValueController/ValueController';
import AmountSymbol from 'components/Elements/AmountSymbol/AmountSymbol';

type IMappedTransaction = {
  trustScore: string;
  hash: string[];
  from: string[];
  to: string[];
  type: string;
  status: boolean;
  currencyHash: string | null | undefined;
  currencySymbol: string | undefined;
  amount: string;
  time: string;
  timestamp: number;
} | null;

const Row = ({ transactionData }:any) => {
  const [transaction, setTransaction] = useState<IMappedTransaction>(null);
  const {windowDimensions: {isDesktop}} = useAppSelector(selectExplorer);

  const mapTransaction = useCallback((() => {
    const { trustChainTrustScore, hash, baseTransactions, type, attachmentTime, transactionConsensusUpdateTime } = transactionData;
    const fromAddresses = getBaseTxByName(baseTransactions,BaseTransactionName.INPUT)
    const uniqueAddresses: string[] = []
    const uniqueAddrMap = getUniqueAddrMap(fromAddresses)
    for(const [key] of Object.entries(uniqueAddrMap)) {
        uniqueAddresses.push(key)
    }

    const getTransactionDestination = () => {
      if(type === "Transfer" || type === "Initial") return baseTransactions.filter((baseTx: IBaseTransaction) => baseTx.name === "RBT").map((baseTx: IBaseTransaction) => baseTx.addressHash)
      if(type === "TokenMinting") return [baseTransactions.filter((baseTx: IBaseTransaction) => baseTx.name === "TMBT")[0].tokenMintingServiceData.receiverAddress];
      return ["N/A"]
    }
    const currencyHash = getTxCurrencyHash(baseTransactions, type);
    const isGenerate = type === transactionTypes.TokenGeneration.type;
    return {
      trustScore: trustChainTrustScore,
      hash: [hash],
      from: uniqueAddresses.length ? uniqueAddresses : ["N/A"],
      to: getTransactionDestination(),
      type: transactionTypes[type].title,
      status: transactionConsensusUpdateTime !== null,
      currencyHash: isGenerate ? undefined : currencyHash,
      currencySymbol: isGenerate ? getTxCurrencySymbol(baseTransactions) : undefined,
      amount:  getTxAmount(baseTransactions, type, currencyHash),
      time: moment(attachmentTime * 1000).fromNow(),
      timestamp: attachmentTime
    }
  }), [transactionData]);

  useEffect(()=>{
    const mappedTransaction = mapTransaction();
    setTransaction(mappedTransaction);
  },[mapTransaction, transactionData]);

  return useMemo(() => transaction && (
    isDesktop ? (
      <div className="table_row transaction_row">
        <RowVisible transaction={transaction} transactionData={transactionData}/>
      </div>
      ) : (
      <Expand className="transaction_expand expand_rows_bg" visible={<RowVisible transaction={transaction} transactionData={transactionData}/>}>
        <RowHidden transaction={transaction} transactionData={transactionData}/>
      </Expand>
    )
  ), [isDesktop, transaction, transactionData]);
}

export default Row;

const RowVisible = ({transaction, transactionData}: any) => {
  const {windowDimensions: {isDesktop, isMobile, isPortraitTablet}} = useAppSelector(selectExplorer);
  const navigate = useNavigate();
  return useMemo(() => (
    <>
      {!isMobile && !isPortraitTablet && <Column colKey="hash">
        <Address
          onClickValue={()=> navigate(`/transaction/${transaction.hash}`, { state: transactionData })}
          addresses={transaction.hash}
          maxWidth='120px'
          eye
        />
      </Column>}

      {isDesktop && (
        <>
          <Column colKey="from">
            <Address addresses={transaction.from} maxWidth='120px' eye />
          </Column>

          <Column colKey="to">
            <Address addresses={transaction.to} maxWidth='120px' eye />
          </Column>

          <Column colKey="token">
            <IconLabel id="icon"
              opt={{currencyHash: transaction.currencyHash, currencySymbol: transaction.currencySymbol, txStatus: transaction.status}}
            />
          </Column>
        </>
      )}

      {!isMobile && (
        <>
          <Column colKey="trustScore">
            <CircleFill value={transaction.trustScore} />
          </Column>

          <Column colKey="status">
            <Status status={transaction.status ? config.txStatuses.confirmed : config.txStatuses.pending} />
          </Column>

          <Column colKey="type">
            <Value value={transaction.type} className="row_value"/>
          </Column>
        </>
      )}

      {!isMobile && (
        <Column colKey="amount">
          {isDesktop ?
            <Value 
              value={commaFormatted(valueMaxLength(transaction.amount, 8, 14))}
              suffix={!isDesktop && transaction.currencySymbol}
              className="row_value"
            />
          :
            <AmountSymbol
              currencyHash={transaction.currencyHash}
              value={commaFormatted(valueMaxLength(transaction.amount, 2, 14))}
              className="row_value"
            />
          }
        </Column>
      )}

      {isMobile ? (
        <>
          <div className="shared_values tx_amount">
          <IconLabel id="icon_only" opt={{currencyHash: transaction.currencyHash, currencySymbol: transaction.currencySymbol, txStatus: transaction.status}} />
            <AmountSymbol
              currencyHash={transaction.currencyHash}
              value={commaFormatted(valueMaxLength(transaction.amount, 2, 14))}
              className="row_value"
            />
          </div>
          <div className="shared_values">
            <Status status={transaction.status ? config.txStatuses.confirmed : config.txStatuses.pending} />
            <Tooltip
              className="to_left"
              text={timestampToDisplay(transaction.timestamp)}
              custom={<Value value={transaction.time} className="row_value time"/>}
            />
          </div>
        </>
      ) : (
        <Column colKey="time">
          <Tooltip
            text={timestampToDisplay(transaction.timestamp)}
            custom={<Value value={transaction.time} className="row_value"/>}
            className="to_left"
          />
        </Column>
      )}
    </>
  ), [isDesktop, isMobile, isPortraitTablet, navigate, transaction, transactionData])
}
const RowHidden = ({transaction, transactionData}: any) => {
  const { trustScore, hash, from, to, status, type, amount, time } = config.tableFields.transactions.desktop
  const {windowDimensions: {isMobile, isTablet, isDesktop}} = useAppSelector(selectExplorer);
  const navigate = useNavigate();
  const isToValueNA = transaction.to[0] === 'N/A'
  const isFromValueNA = transaction.from[0] === 'N/A'
  return useMemo(() => (
    <>
      <ExpandRow className="expand_row hash">
        <Label label={hash.label} tooltip={hash.tooltip} />
        <ValueController
          className='row_value value_link'
          value={transaction.hash}
          onClickValue={()=> navigate(`/transaction/${transaction.hash}`, { state: transactionData })}
          copy='green'
          maxWidth="448px"
        />
      </ExpandRow>

      {transaction.from.length > 1 ? (
        <Expand className="from" visible={
          <>
            <Label label={from.label} tooltip={from.tooltip} />
            <Address addressesLength={transaction.from.length}/>
          </>
        }>
          {transaction.from.map((address: string, idx: number) => (
            <ExpandRow key={address} className="expand_row">
              <ValueController
                className='value_link'
                onClickValue={() => navigate(`/address/${address}`)} 
                value={address}
                copy='green'
                maxWidth="448px"
              />
            </ExpandRow>
          ))}
        </Expand>
      ) : (
        <ExpandRow className="expand_row from">
          <Label label={from.label} tooltip={from.tooltip} />
          <ValueController
            className={`row_value ${!isFromValueNA ? 'value_link' : ''}`}
            value={transaction.from}
            onClickValue={()=> !isFromValueNA ? navigate(`/address/${transaction.from}`) : null}
            copy={!isFromValueNA ? 'green' : false}
            maxWidth="448px"
          />
        </ExpandRow>
      )}
      

      <ExpandRow className="expand_row to">
        <Label label={to.label} tooltip={to.tooltip} />
        <ValueController
          className={`row_value ${!isToValueNA ? 'value_link' : ''}`}
          value={transaction.to}
          onClickValue={()=> !isToValueNA ? navigate(`/address/${transaction.to}`) : null}
          copy={!isToValueNA ? 'green' : false}
          maxWidth="448px"
        />
      </ExpandRow>

      {!isTablet &&
        <ExpandRow className="expand_row amount">
          <Label label={amount.label} tooltip={amount.tooltip} />
          <Value value={commaFormatted(valueMaxLength(transaction.amount, 8, 14))} className="row_value"/>
        </ExpandRow>
      }
      
      {isMobile && (
        <>
          <ExpandRow className="expand_row trustScore">
            <Label label={trustScore.label} tooltip={trustScore.tooltip} />
            {isDesktop ? <CircleFill value={transaction.trustScore} /> : <Value value={Math.floor(transaction.trustScore)} className="row_value"/>}
          </ExpandRow>

          <ExpandRow className="expand_row status">
            <Label label={status.label} tooltip={status.tooltip} />
            <Status status={transaction.status ? config.txStatuses.confirmed : config.txStatuses.pending} />
          </ExpandRow>

          <ExpandRow className="expand_row type">
            <Label label={type.label} tooltip={type.tooltip} />
            <Value value={transaction.type} className="row_value"/>
          </ExpandRow>

          <ExpandRow className="expand_row time">
            <Label label={time.label} tooltip={time.tooltip} />
            <Tooltip
              text={timestampToDisplay(transaction.timestamp)}
              custom={<Value value={transaction.time} className="row_value"/>}
            />
          </ExpandRow>
        </>
      )}

    </>
  // eslint-disable-next-line
  ), [isMobile, isTablet, navigate, transaction, transaction.type, transactionData])
}