import React from 'react';
import ValueController from 'components/Elements/ValueController/ValueController';
import { useNavigate } from 'react-router';
import './Addresses.scss'

export const MapAddresses = ({addresses, onClickValue}:any) => {
  const navigate = useNavigate();
  return addresses.map((address: string, idx: number) => (
    <ValueController
      className='value_link address_tooltip'
      onClickValue={() => onClickValue ? onClickValue() : navigate(`/address/${address}`)} 
      key={`${idx}-${address}`}
      value={address}
      copy
      maxWidth
    />
  ))
}