import React, { useMemo } from 'react';
import { config } from 'config/config';
import Label from 'components/Elements/Label/Label';
import Row from 'components/Elements/Row/Row';
import ValueController from 'components/Elements/ValueController/ValueController';
import TokenDetails from '../TokenDetails';
import { useNavigate } from 'react-router';

const MintDetails = ({to, token}: any) => {
  const navigate = useNavigate();
  return useMemo(() => (
    <>
      <TokenDetails token={token} />

      <Row className="to">
        <Label label={config.transactionDetails.to.label} tooltip={config.transactionDetails.to.tooltip} />
        <ValueController value={to} copy="green" maxWidth onClickValue={() => navigate(`/address/${to}`)} className="value_link"/>
      </Row>
    </>
  ), [navigate, to, token]);
}

export default MintDetails;