// =======================================================================================
// Transaction
// =======================================================================================

export interface ITransaction {
    amount: string;
    attachmentTime: string;
    baseTransactions: IBaseTransaction[];
    createTime: string;
    hash: string;
    index: number;
    isValid: null;
    leftParentHash: string;
    rightParentHash: null;
    senderHash: null;
    senderTrustScore: string;
    status: string;
    transactionConsensusUpdateTime: null;
    transactionDescription: string;
    trustChainConsensus: boolean;
    trustChainTrustScore: string;
    type: string;
}

export interface IBaseTransaction {
    addressHash: string;
    amount: string;
    createTime: string;
    currencyHash: null | string;
    hash: string;
    name: string;
    originalAmount ? : string;
    originalCurrencyHash ? : null | string;
    reducedAmount ? : string;
    receiverDescription ? : string;
    tokenMintingServiceData ? : ITokenMintingServiceData;
    tokenGenerationServiceData ? : ITokenGenerationServiceData;
}

export interface ITokenMintingServiceData {
    createTime: string;
    feeAmount: string;
    mintingAmount: string;
    mintingCurrencyHash: string;
    receiverAddress: string;
    signerHash: string;
}

export interface ITokenGenerationServiceData {
    currencyTypeData: ICurrencyTypeData;
    feeAmount: string;
    originatorCurrencyData: IOriginatorCurrencyData
}

export interface IOriginatorCurrencyData {
    description: string;
    name: string;
    originatorHash: string;
    scale: number;
    symbol: string;
    totalSupply: string;
}

export interface ICurrencyTypeData {
    createTime: string;
    currencyRateSourceType: string;
    currencyType: string;
    protectionModel: string;
    rateSource: string;
    signerHash: string;
}

export interface ITransactionsState {
    transaction: ITransaction | null;
    totalTransactions: number | null;
    nativeBalance: string | null;
    transactionsBalances: ITransactionsBalance[] | null;
    transactions: ITransaction[] | null;
    lastTransactions: ITransaction[] | null;
    connected: boolean;
    limit: number;
    offset: number;
    isLoading: boolean;
}

export interface ITransactionsBalance {
    [key:string]: {
        balance: string;
        symbol: string;
    }
}

// =======================================================================================
// Explorer Slice
// =======================================================================================

type IExplorerType = string | null;

export type ISearchType = {
    nodes: any[];
    tokens: IToken[];
    addresses: any;
    transactions: any;
};

export interface IExplorerState {
  price: IExplorerType;
  treasuryTVL: IExplorerType;
  txConfirmationTime: IExplorerType;
  searchResults: ISearchType;
  searchResultsFullfil: boolean;
  windowDimensions: {
    innerHeight: number;
    innerWidth: number;
    isMobile: boolean;
    isPortraitTablet: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
}

// =======================================================================================
// Tokens Slice
// =======================================================================================

export interface ITokensState {
    tokens: { 
        [key:string]: IToken 
    };
    fulfilled: boolean; 
    rejected: boolean; 
    offset: number;
    limit: number;
    totalTokens: number | null;
    isLoading: boolean;
}

export interface IToken {
    name: string;
    symbol: string;
    createTime?: string;
    description?: string;
    originatorHash?: string;
    totalSupply?: string;
    scale: number;
    currencyHash: string;
    icon?: string;
    website?: string;
    twitter?: string;
    discord?: string;
    telegram?: string;
    gitbook?: string;
    medium?: string;
    circulatingSupply?: string;
    attachmentTime?: string;
}

// =======================================================================================
// Socket Events
// =======================================================================================

export enum SocketEvents {
    GeneralTransactionsNotification = 'transactions',
    AddressTransactionsNotification = 'addressTransactions',
    AddressTransactionsTotalNotification = 'addressTransactionsTotal',
    NodeTransactionsNotification = 'nodeTransactions',
    TokenTransactionsNotification = 'tokenTransactions',
    TransactionDetails = 'transactionDetails',
    NodeUpdates = 'nodeUpdates',
    TransactionConfirmationUpdate = 'transactionConfirmationUpdate',
    TreasuryTotalsUpdates = 'treasuryTotalsUpdates',
    Transactions = 'transactions',
    TokenTransactionsTotal = 'tokenTransactionsTotal',
    AddressBalanceUpdate = 'addressBalanceUpdate',
    TokenCirculatingSupplyUpdate = 'tokenCirculatingSupplyUpdate',
    CotiPrice = 'cotiPrice',
    NewTokens = 'newToken',
    TokensTotal = 'tokensTotal',
}