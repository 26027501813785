import { Explorer } from 'features/explorer/Explorer';
import { Transactions } from 'features/transactions/Transactions';
import React, { useMemo } from 'react';

const Home: React.FC = () => {
    return useMemo(() => (<>
        <Explorer />
        <Transactions />
    </>), []);
}

export default Home;