import React, { useMemo } from 'react';
import IconLabel from 'components/Elements/IconLabel/IconLabel';
import Value from 'components/Elements/Value/Value';
import Column from 'components/Elements/Table/Column';
import { commaFormatted, timestampToDisplay } from 'utilities';
import { useNavigate } from 'react-router';
import { Address } from 'components/Elements/Addresses';
import { selectExplorer } from 'features/explorer/explorerSlice';
import { useAppSelector } from 'app/hooks';
import Expand from 'components/Elements/Expand/Expand';
import { config } from 'config/config';
import Label from 'components/Elements/Label/Label';
import {Row as ExpandRow} from 'components/Elements/Row/Row';
import ValueController from 'components/Elements/ValueController/ValueController';
import moment from 'moment';
import './Row.scss'
interface IToken {
  name: string;
  symbol: string;
  description: string;
  originatorHash: string;
  totalSupply: string;
  scale: number;
  currencyHash: string;
  discord: null;
  gitbook: null;
  medium: null;
  telegram: null;
  twitter: null;
  website: null;
  circulatingSupply?: string;
  icon?: string;
  trustChainSupply?: string;
  attachmentTime: string;
}
interface Props {
  token: IToken;
  key?: number | string;
}

const Row:React.FC<Props>=({token}) => {
  const navigate = useNavigate();
  const {windowDimensions: {isDesktop,isMobile}} = useAppSelector(selectExplorer);
  return useMemo(() => isDesktop ? (
      <div className="table_row tokens_row">
        <Column colKey="nameSymbol">
          <IconLabel
            id="tokenlist"
            opt={{currencyHash: token.currencyHash}}
          />
        </Column>
  
        <Column colKey="maxSupply">
          <Value
            value={commaFormatted(token.totalSupply)}
            className="row_value"
          />
        </Column>
  
        <Column colKey="circulatingSupply">
          <Value
            value={token.circulatingSupply ? commaFormatted(token.circulatingSupply) : "//"}
            className="row_value"
          />
        </Column>

        <Column colKey="trustChainSupply">
          <Value
            value={token.trustChainSupply ? commaFormatted(token.trustChainSupply) : '//'}
            className="row_value"
          />
        </Column>

        <Column colKey="createTime">
          <Value
              value={timestampToDisplay(token.attachmentTime)}
              className="row_value"
            />
        </Column>

        <Column colKey="currencyHash">
          <Address
            onClickValue={() => navigate(`/token/${token.currencyHash}`)}
            addresses={[token.currencyHash]}
            maxWidth
            eye
          />
        </Column>
      </div>
    ) : (
      <Expand className="tokens_expand expand_rows_bg" visible={
        <>
          <IconLabel id="tokenlist" opt={{currencyHash: token.currencyHash}} />
          <Column colKey="createTime">
            <Value
                value={isMobile ? moment.utc(Number(token.attachmentTime) * 1000).format('DD MMM, YYYY') : timestampToDisplay(token.attachmentTime)}
                className="row_value"
              />
          </Column>
        </>
      }>
        <RowHidden token={token}/>
      </Expand>
    ), [isDesktop, isMobile, navigate, token])
}

export default Row;

const RowHidden:React.FC<Props>=({token}) => {
  const { maxSupply, circulatingSupply, trustChainSupply, currencyHash } = config.tableFields.tokens
  const navigate = useNavigate();
  return useMemo(() => (
    <>
      <ExpandRow className="expand_row maxSupply">
        <Label label={maxSupply.label} tooltip={maxSupply.tooltip} />
        <Value
          value={commaFormatted(token.totalSupply)}
          className="row_value"
        />
      </ExpandRow>

      <ExpandRow className="expand_row circulatingSupply">
        <Label label={circulatingSupply.label} tooltip={circulatingSupply.tooltip} />
        <Value
          value={token.circulatingSupply ? commaFormatted(token.circulatingSupply) : "//"}
          className="row_value"
        />
      </ExpandRow>

      <ExpandRow className="expand_row trustchainSupply">
        <Label label={trustChainSupply.label} tooltip={trustChainSupply.tooltip} />
        <Value
          value={token.trustChainSupply ? commaFormatted(token.trustChainSupply) : '//'}
          className="row_value"
        />
      </ExpandRow>

      <ExpandRow className="expand_row currencyHash">
        <Label label={currencyHash.label} tooltip={currencyHash.tooltip} />
        <ValueController
          className='row_value value_link'
          value={token.currencyHash}
          onClickValue={() => navigate(`/token/${token.currencyHash}`)}
          copy='green'
          maxWidth
        />
      </ExpandRow>
    </>
  // eslint-disable-next-line
  ), [navigate, token])
}