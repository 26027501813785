import React from 'react';
import './Link.scss'

const Link = ({to, children, newWindow, className, props}:any) => {
  return (
    <a href={to}
    className={`custom_link ${className ?? ''}`}
    {...(newWindow ? { target:"_blank" ,rel:"noopener noreferrer"} : {})} {...props}>
        {children}
    </a>
  )
}

export default Link;