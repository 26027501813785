import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IExplorerState } from 'utilities/types';
import { fetchPrice, fetchTreasuryTVL, fetchTxConfirmationTimeAVG, searchRequest } from './explorerAPI';

export const defaultResults = {
  tokens: [],
  nodes: [],
  addresses: [],
  transactions: []
}

export const initialState: IExplorerState = {
  price: null,
  treasuryTVL: null,
  txConfirmationTime: null,
  searchResults: defaultResults,
  searchResultsFullfil: false,
  windowDimensions: {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    isMobile: window.innerWidth < 768,
    isPortraitTablet: window.innerWidth >= 768 && window.innerWidth < 1024,
    isTablet: window.innerWidth >= 1024 && window.innerWidth < 1366,
    isDesktop: window.innerWidth >= 1366
  }
};

export const search = createAsyncThunk(
  'explorer/search',
  async (criteria: string) => {
    const response = await searchRequest(criteria);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getCotiPrice = createAsyncThunk(
  'explorer/price',
  async () => {
    const response = await fetchPrice();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getTreasuryTVL = createAsyncThunk(
  'explorer/treasuryTVL',
  async () => {
    const response = await fetchTreasuryTVL();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getTxConfirmationTime = createAsyncThunk(
  'explorer/txConfirmationTime',
  async () => {
    const response = await fetchTxConfirmationTimeAVG();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const explorerSlice = createSlice({
  name: 'explorer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    subscribe: (state: IExplorerState, { payload }: any ) => {

    },

    unsubscribe: (state:IExplorerState, { payload }: PayloadAction<any>) => {

    },

    resetSearchResults: (state: IExplorerState) => {
      state.searchResults = defaultResults;
      state.searchResultsFullfil = false;
    },
    explorerEventsSubscription: (state: IExplorerState) => {

    },
    setWindowDimensions: (state: IExplorerState, {payload}: any) => {
      const {innerHeight, innerWidth} = payload
      state.windowDimensions = {
        innerHeight, 
        innerWidth,
        isMobile: innerWidth < 768,
        isPortraitTablet: innerWidth >= 768 && innerWidth < 1024,
        isTablet: innerWidth >= 1024 && innerWidth < 1366,
        isDesktop: innerWidth >= 1366
      }
    },
    onTransactionConfirmationEvent: (state: IExplorerState, { payload }: any) => {
      state.txConfirmationTime = payload;
    },
    onTreasuryTotalsEvent: (state: IExplorerState, { payload }: any) => {
      state.treasuryTVL = payload;
    },
    onCotiPriceEvent: (state: IExplorerState, { payload }: any) => {
      state.price = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCotiPrice.fulfilled, (state, {payload}) => {
        state.price = payload.price
      })
      .addCase(getCotiPrice.rejected, (state) => {
        state.price = null
      })
      .addCase(getTreasuryTVL.fulfilled, (state, {payload}) => {
        state.treasuryTVL = payload.totalCotiInPool
      })
      .addCase(getTreasuryTVL.rejected, (state) => {
        state.treasuryTVL = null
      })
      .addCase(getTxConfirmationTime.fulfilled, (state, {payload}) => {
        state.txConfirmationTime = payload.average
      })
      .addCase(getTxConfirmationTime.rejected, (state) => {
        state.txConfirmationTime = null
      })
      .addCase(search.fulfilled, (state, { payload }) => {
        delete payload.nodes
        state.searchResults = payload
        state.searchResultsFullfil = true
      })
      .addCase(search.rejected, (state) => {
        state.searchResultsFullfil = false
      })
  },
});

export const { resetSearchResults,
  setWindowDimensions,
  explorerEventsSubscription,
  onTransactionConfirmationEvent,
  onTreasuryTotalsEvent,
  onCotiPriceEvent,
  subscribe,
  unsubscribe } = explorerSlice.actions;

export const selectExplorer = (state: RootState) => state.explorer;

export default explorerSlice.reducer;