import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { explorerEventsSubscription, getCotiPrice, getTreasuryTVL, getTxConfirmationTime } from './explorerSlice';
import MainStats from 'components/Elements/Stat/MainStats';
import { selectTransactions } from 'features/transactions/transactionsSlice';

export function Explorer() {
  const dispatch = useAppDispatch();
  const {connected} = useAppSelector(selectTransactions);

  useEffect(() => {
    dispatch(getCotiPrice());
    dispatch(getTreasuryTVL());
    dispatch(getTxConfirmationTime());
  }, [dispatch]);

  useEffect(()=>{
    if(connected) {
      dispatch(explorerEventsSubscription());
    }
  },[dispatch, connected])

  return useMemo(()=> {
    return (
      <div>
        <MainStats/>
      </div> 
    )
  },[]);
}
