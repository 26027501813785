import React, { useMemo } from 'react';
import Tooltip from 'components/Elements/Tooltip/Tooltip';
import './Label.scss';

const Label = ({ label, tooltip, className }: any) => {
  return useMemo(() => (
    <div className={`label_component ${className ?? ''}`}>
      {tooltip && <Tooltip text={tooltip}/>}
      <p className='label'>{label}</p>
    </div>
  ), [className, label, tooltip])
}

export default Label;
