import { Reducer } from 'redux'
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import SocketClient from 'api/SocketClient';
import explorerReducer from 'features/explorer/explorerSlice';
import socketMiddleware from 'middleware/socketMiddleware';
import { IExplorerState, ITransactionsState } from 'utilities/types';
import transactionReducer from '../features/transactions/transactionsSlice';
import tokensReducer from '../features/tokens/tokensSlice';

const socket = new SocketClient();

export const rootReducer: Reducer<RootState> = combineReducers<RootState>({
  explorer: explorerReducer,
  transactions: transactionReducer,
  tokens: tokensReducer
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketMiddleware(socket)),
  devTools: process.env.NODE_ENV === 'development'
});

export type AppDispatch = typeof store.dispatch;

export interface RootState {
  transactions: ITransactionsState
  explorer: IExplorerState,
  tokens: any
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
