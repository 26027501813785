import React from 'react';
import trustchainLogo from 'images/logos/coti_trustchain.svg';
import styles from './PoweredBy.module.scss';

const PoweredBy = () => {
  return (
    <div className={styles.powered_By}>
      Powered By COTI 
      <img className={styles.logo} src={trustchainLogo} alt='Trustchain'/>
    </div>
  )
}

export default PoweredBy;