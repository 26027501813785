import api from '../../api/api';
import { PaginationParams } from './transactionsSlice';

export function fetchLast({limit, offset}:PaginationParams) {
  return api.lastTransactions(limit ?? 10 ,offset ?? 0);
}

export function fetchTransaction(txHash: string) {
  return api.getTransactionByHash(txHash);
}

export function fetchAddressTransactions(addressHash: string, limit: number, offset: number) {
  return api.getTransactionsByAddress(addressHash, limit, offset);
}

export function fetchTokenTransactions(currencyHash: string, limit: number, offset: number) {
  return api.getTransactionsByToken(currencyHash, limit, offset);
}