import React from 'react';
import styles from './View.module.scss';

type props = any | null

const View = (props:props) => {
  return (
    <div className={styles.view}>
        {props.children}
    </div>
  )
}

export default View;