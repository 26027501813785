import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchToken, fetchTokenBySymbol, fetchTokenIcon, fetchTokens } from './tokensAPI';
import { ITokensState } from 'utilities/types';
import { toBuffer } from 'utilities';
import TokenAvatar from '@coti-io/crypto/dist/utils/avatar';

export const initialState: ITokensState = {
  tokens: {
    
  },
  fulfilled: false,
  rejected: false,
  offset: 0,
  limit: 10,
  totalTokens: null,
  isLoading: false,
};

export type PaginationParams = {
  limit?: number;
  offset?: number;
}

export const getTokens = createAsyncThunk(
  'tokens/getTokens',
  async (params:PaginationParams) => {
    const response = await fetchTokens(params)
    return response.data;
  }
);

export const getToken = createAsyncThunk(
  'tokens/getToken',
  async (currencyHash: any) => {
    const response:any = await fetchToken(currencyHash);
    let iconBuffer;
    try {
      const icon = await fetchTokenIcon(currencyHash);
      if(icon.data) {
        iconBuffer = toBuffer(icon.data);
      }
    } catch (error) {
      const avatar = new TokenAvatar(response.data.symbol);
      iconBuffer = toBuffer(avatar.toSvg());
    }
    return { ...response.data, icon: iconBuffer }
  }
);

export const getTokenBySymbol = createAsyncThunk(
  'tokens/getTokenBySymbol',
  async (currencySymbol: any) => {
    let response:any;
    
    try {
      response = await fetchTokenBySymbol(currencySymbol);
    } catch (error) {
      await new Promise((res)=> setTimeout(res,5000));
      response = await fetchTokenBySymbol(currencySymbol);
    }
    
    let iconBuffer;
    try {
      const icon = await fetchTokenIcon(response.data.currencyHash);
      if(icon.data) {
        iconBuffer = toBuffer(icon.data);
        return { ...response.data, icon: iconBuffer }
      }
    } catch (error) {
      const avatar = new TokenAvatar(response.data.symbol);
      iconBuffer = toBuffer(avatar.toSvg());
    }
    
    return { ...response.data, icon: iconBuffer }
  }
);

export const getTokenIcon = createAsyncThunk(
  'tokens/getTokenIcon',
  async ({currencyHash, symbol}: {currencyHash: any, symbol: any}) => {
    let iconBuffer;
    try {
      const icon = await fetchTokenIcon(currencyHash);
      if(icon.data) {
        iconBuffer = toBuffer(icon.data);
      }
    } catch (error) {
      const avatar = new TokenAvatar(symbol);
      iconBuffer = toBuffer(avatar.toSvg());
    }
    return {currencyHash, icon: iconBuffer}
  }
);

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    onTokenCirculatingSupplyUpdate: (state: ITokensState, { payload }: PayloadAction<any>) => {
      state.tokens = {
        ...state.tokens,
        [payload.currencyHash]: {
          ...state.tokens[payload.currencyHash],
          ...payload
        }
      }
    },
    tokensSubscription: () => {},

    onNewTokenEvent: (state: ITokensState, { payload }: PayloadAction<any>) => {
      if (!state.offset) {
        state.tokens = {
          ...state.tokens,
          [payload.currencyHash] : {
            ...payload,
          }
        }
      }
    },

    totalTokensUpdate: (state: ITokensState, { payload }: PayloadAction<any>) => {
      state.totalTokens = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTokens.fulfilled, (state, {payload}) => {
        if(payload?.tokenInfoList?.length){
          const tokensObj: { [x: string]: any; } = {}
          payload.tokenInfoList.forEach((token:any) => {
            tokensObj[token.currencyHash] = token;
          });
          state.tokens = tokensObj;
          state.fulfilled = true;
          state.rejected = false;
          state.totalTokens = payload.count;
          state.isLoading = false;
        }
      })
      .addCase(getTokens.pending, (state, {meta}) => {
        state.isLoading = true;
        state.offset = meta.arg.offset ?? 0;
      })
      .addCase(getTokens.rejected, (state) => {
        state.rejected = true
        state.isLoading = false;
      })
      .addCase(getToken.fulfilled, (state: ITokensState, {payload}) => {        
        if(!payload.currencyHash) return;
        state.tokens = {
          ...state.tokens,
          [payload.currencyHash]: payload
        }
      })
      .addCase(getTokenBySymbol.fulfilled, (state: ITokensState, {payload}) => {        
        if(!payload.currencyHash) return;
        state.tokens = {
          ...state.tokens,
          [payload.currencyHash]: payload
        }
      })
      .addCase(getTokenIcon.fulfilled, (state: {tokens: any }, {payload}) => {
        if(payload === undefined) return;
        const token = state.tokens[payload.currencyHash];
        if(!token) return;
        
        state.tokens = {
          ...state.tokens,
          [payload.currencyHash] : {
            ...token,
            icon: payload.icon
          }
        }
      })
    }
});

export const {onTokenCirculatingSupplyUpdate, tokensSubscription, onNewTokenEvent, totalTokensUpdate} = tokensSlice.actions;

export const selectTokens = (state: RootState) => state.tokens;

export default tokensSlice.reducer;