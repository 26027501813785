import React, { useEffect, useMemo, useRef } from 'react';
import styles from './Input.module.scss';

const Input = (props: any) => {
  const inputRef: any = useRef()
  useEffect(() => {
    if (props.autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [props.autoFocus])

  return useMemo(() => (
    <>
      <input
        ref={inputRef}
        type={props.type ?? 'text'}
        className={styles.input_component}
        value={props.value ?? ''}
        onChange={props.onChange}
        {...props}
        placeholder=""
      />
      {props.placeholder && props.value === '' && <p className={styles.input_placeholder} >{props.placeholder}</p>}
    </>
  ), [props])
}

export default Input;