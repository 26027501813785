import React, { useEffect, useMemo, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import Search from 'components/Elements/Search/Search';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import PageLayout from 'components/PageLayout/PageLayout';
import View from 'components/View/View';
import Home from 'features/home/Home';
import { Transactions } from './features/transactions/Transactions';
import { Tokens } from './features/tokens/Tokens';
import TransactionDetails from 'features/transactions/TransactionDetails/TransactionDetails';
import AddressDetails from 'features/address/AddressDetails';
import TokenDetails from 'features/tokens/TokenDetails/TokenDetails';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { connect, selectTransactions } from 'features/transactions/transactionsSlice';
import { setWindowDimensions } from 'features/explorer/explorerSlice';
import _ from 'lodash';

function App() {
  const state = useAppSelector(selectTransactions);
  const dispatch = useAppDispatch();
  
  // eslint-disable-next-line
  const debouncedHandleResize = useCallback(_.debounce((event:any) => dispatch(setWindowDimensions({innerHeight: event.currentTarget.innerHeight, innerWidth: event.currentTarget.innerWidth})), 500),[]);

  useEffect(() => {
    if(!state.connected) {
      dispatch(connect());
    }
  }, [dispatch, state.connected]);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [dispatch, debouncedHandleResize]);

  return useMemo(()=> {
    return (
      <div className="App">
        <Header/>
        <PageLayout>
          <Search />
          <View>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path={`/transaction/:txhash`} element={<TransactionDetails />} />
              <Route path={`/address/:addresshash`} element={<AddressDetails />} />
              <Route path="/tokens" element={<Tokens />} />
              <Route path={`/token/:tokenhash`} element={<TokenDetails />} />
            </Routes>
          </View>
        </PageLayout>
        <Footer/>
      </div>
    )
  },[])
}

export default App;