import React, { useState, useMemo } from "react";
import "./Expand.scss";

const Expand = ({ visible, children, className, disabled }:any) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return useMemo(() => {
    return (
      <div className={`expand_component ${className ?? ''} ${isOpen ? 'open' : ''} ${ disabled ? 'disabled' : ''}`} onClick={() => !disabled && setIsOpen(!isOpen)}>
        <div className="expand_visible">
          {visible}
        </div>

        {isOpen && 
          <div className="expanded" onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        }

      <div className={`app_icon expand_arrow ${disabled ? 'disabled' : ''}`} style={isOpen ? { transform: "rotate(180deg)" } : {}}/>
      </div>
    );
  }, [className, isOpen, visible, children, disabled]);
};

export default Expand;
