import React, { useMemo } from 'react';
import { NetworkDropdown } from 'components/Elements/Dropdown/Dropdown';
import Logo from 'components/Elements/Logo/Logo';
import Menu from 'components/Menu/Menu';
import Layout from 'components/Layout/Layout';
import styles from './Header.module.scss';
import { useAppSelector } from 'app/hooks';
import { selectExplorer } from 'features/explorer/explorerSlice';

const Header = () => {
  const {windowDimensions: {isMobile}} = useAppSelector(selectExplorer);

  return useMemo(() => (
    <div className={styles.header}>
      <div className={styles.navbar}>
        <Logo />
        {!isMobile && <NetworkDropdown className="capitalize"/>}
      </div>
      <Layout>
        <Menu />
      </Layout>
    </div>
  ), [isMobile])
}

export default Header;