import React, { useEffect, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { config } from 'config/config';
import Label from 'components/Elements/Label/Label';
import Row from 'components/Elements/Row/Row';
import ValueController from 'components/Elements/ValueController/ValueController';
import Value from 'components/Elements/Value/Value';
import { commaFormatted, timestampToDisplay } from 'utilities';
import './TokenDetails.scss';
import IconLabel, { useToken } from 'components/Elements/IconLabel/IconLabel';
import Link from 'components/Elements/Link/Link';
import Chip from 'components/Elements/Chip/Chip';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectTransactions, tokenSubscription } from 'features/transactions/transactionsSlice';
import { Transactions } from 'features/transactions/Transactions';
import { unsubscribe } from 'features/explorer/explorerSlice';

const TokenDetails = () => {
    const { name, symbol, currencyHash, originatorHash, maxSupply, circulatingSupply, scale, socialMedia, createTime } = config.tokenDetails
    const match = useMatch('token/:tokenhash');
    const dispatch = useAppDispatch();
    const tokenHash = match?.params?.tokenhash;
    const [ tokenDetails ] = useToken(tokenHash);
    const {connected} = useAppSelector(selectTransactions);
    const navigate = useNavigate();
    useEffect(()=>{
        if(tokenHash === config.cotiAsset.currencyHash) navigate('/', {state: { frompage: 'token', token: config.cotiAsset.currencyHash}});
        if(tokenHash && connected) {
            dispatch(tokenSubscription({tokenHash, tokenAllEvents: true}));
        }

        return () => {
            dispatch(unsubscribe({tokenHash}));
        }

    },[connected, dispatch, tokenHash, navigate])
    return useMemo(() => tokenDetails ? (
    <>
        <div className='inner_page token_details'>
            <div className="details_section">
                <div className="inner_header">
                    <IconLabel
                        id="tokenDetails"
                        opt={{currencyHash: tokenDetails.currencyHash}}
                    />
                    <Chip label="Token"/>
                </div>

                <p className="desc">
                    <span><b>{tokenDetails.name} ({tokenDetails.symbol})</b>&nbsp;</span>
                    was generated on<span>&nbsp;{timestampToDisplay(tokenDetails.attachmentTime)}</span> on top of COTI's Trustchain utilizing COTI's MultiDAG (CMD) protocol.
                </p>
            </div>

            <div className="details_section">
                <Row>
                    <Label label={name.label} tooltip={name.tooltip}/>
                    <Value value={tokenDetails.name} className="row_value currencyName"/>
                </Row>
                <Row>
                    <Label label={symbol.label} tooltip={symbol.tooltip}/>
                    <Value value={tokenDetails.symbol} className="row_value"/>
                </Row>
                <Row>
                    <Label label={currencyHash.label} tooltip={currencyHash.tooltip}/>
                    <ValueController value={tokenDetails.currencyHash} copy="green" maxWidth/>
                </Row>
                <Row>
                    <Label label={originatorHash.label} tooltip={originatorHash.tooltip}/>
                    <ValueController value={tokenDetails.originatorHash} copy="green" maxWidth/>
                </Row>
                {/* {tokenDetails.holders && <Row>
                    <Label label={holders.label} tooltip={holders.tooltip}/>
                    <Value value={commaFormatted(tokenDetails.holders)} className="row_value"/>
                </Row>} */}
            </div>

            <div className="details_section">
                <Row>
                    <Label label={createTime.label} tooltip={createTime.tooltip}/>
                    <Value value={timestampToDisplay(tokenDetails.attachmentTime)} className="row_value"/>
                </Row>

                {tokenDetails.totalSupply && <Row>
                    <Label label={maxSupply.label} tooltip={maxSupply.tooltip}/>
                    <Value value={commaFormatted(tokenDetails.totalSupply)} className="row_value"/>
                </Row>}
                {tokenDetails.circulatingSupply && <Row>
                    <Label label={circulatingSupply.label} tooltip={circulatingSupply.tooltip}/>
                    <Value value={commaFormatted(tokenDetails.circulatingSupply)} className="row_value"/>
                </Row>}
                {/* <Row>
                    <Label label={trustchainSupply.label} tooltip={trustchainSupply.tooltip}/>
                    <Value value={commaFormatted(tokenDetails.trustchainSupply)} className="row_value"/>
                </Row> */}
                <Row>
                    <Label label={scale.label} tooltip={scale.tooltip}/>
                    <Value value={tokenDetails.scale} className="row_value"/>
                </Row>
                {/* <Row>
                    <Label label={websiteURL.label} tooltip={websiteURL.tooltip}/>
                    <Link to={tokenDetails.websiteURL} newWindow>{token.websiteURL}</Link>
                </Row> */}
                {tokenDetails.twitter && <Row>
                    <Label label={socialMedia.label} tooltip={socialMedia.tooltip}/>
                    <Link to={`https://twitter.com/${tokenDetails.twitter}`} newWindow><div className="app_icon twitter"/></Link>
                </Row>}
            </div>
        </div>
        <Transactions />
    </>
  // eslint-disable-next-line
  ) : null , [tokenDetails])
}

export default TokenDetails;