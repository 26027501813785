import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { copy as copyAction } from 'utilities';
import Tooltip from 'components/Elements/Tooltip/Tooltip';
import styles from './Copy.module.scss';

const Copy = ({text, onCopied, copy}: any) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timer: any;
    if (copied && !timer) {
      timer = setTimeout(() => setCopied(false), 1500);
    }
    return () => clearTimeout(timer);
  }, [copied]);

  const onCopy = useCallback((e: any) => {
    copyAction(text);
    setCopied(true)
    if (onCopied) {
      onCopied();
    }
    e.stopPropagation();
  },[onCopied, text]);
  
  return useMemo(() => (
    <Tooltip
      className="to_left"
      text={copied ? `Copied!` : 'Copy to clipboard'}
      custom={<div className={`app_icon ${styles.copy_component} ${copy === 'green' ? styles['green'] : ''}`} onClick={onCopy} />} />
  ), [copied, copy, onCopy]);
}

export default Copy;