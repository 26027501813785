import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMatch, useLocation } from 'react-router-dom';
import { config, BaseTransactionName, transactionTypes } from 'config/config';
import { commaFormatted, getBaseTxByName, getTxAmount, getTxCurrencyHash, getTxCurrencySymbol, getTxFees, getUniqueAddrMap, timestampToDisplay, useNode, valueMaxLength } from 'utilities';
import Expand from 'components/Elements/Expand/Expand';
import Label from 'components/Elements/Label/Label';
import Row from 'components/Elements/Row/Row';
import ValueController from 'components/Elements/ValueController/ValueController';
import { Address } from 'components/Elements/Addresses';
import Value from 'components/Elements/Value/Value';
import IconLabel, { useToken } from 'components/Elements/IconLabel/IconLabel';
import AmountPerAddress from './AmountPerAddress';
import MintDetails from './Type/MintDetails';
import GenerateDetails from './Type/GenerateDetails';
import TransferDetails from './Type/TransferDetails';
import './TransactionDetails.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getTransaction, resetTransaction, selectTransactions, transactionSubscription } from '../transactionsSlice';
import { IBaseTransaction, ITransaction } from 'utilities/types';
import { useNavigate } from 'react-router';
import Chip from 'components/Elements/Chip/Chip';
import { unsubscribe } from 'features/explorer/explorerSlice';

type IFees = { 
  financialFee: string;
  nodeFee: string;
  totalFees: string; 
}

type IMappedTransaction = {
  hash: string;
  attachmentTime: string;
  type: string;
  baseTransactions: IBaseTransaction[];
  trustChainTrustScore: string;
  fees: IFees;
  currencyHash: string | null | undefined;
  currencySymbol: string | undefined;
  amount: string;
  transactionConsensusUpdateTime: string | null;
  uniqueAddresses: { addressHash: string; amounts: any; }[];
  nodeHash: string;
  description: string;
} | null ;

const TransactionDetails = () => {
  const navigate = useNavigate();
  const [mappedTransaction, setMappedTransaction] = useState<IMappedTransaction>(null);
  const { transaction, connected } = useAppSelector(selectTransactions);
  const {cotiAsset, txStatuses, transactionDetails:{ hash, attachmentTime, type, trustScore, from, amount, fees, networkFees, fullNodeFees, processingNode, confirmationTime, status }} = config
  const match = useMatch('/transaction/:txhash');
  const location = useLocation();
  const locationState = location.state as ITransaction;
  const dispatch = useAppDispatch();
  const [ asset ] = useToken(mappedTransaction?.currencyHash, mappedTransaction?.currencySymbol, mappedTransaction?.transactionConsensusUpdateTime !== null);
  const nodeDeatils = useNode(mappedTransaction?.nodeHash)
  
  const mapTransaction = useCallback((txData: any) => {
    const fromAddresses = getBaseTxByName(txData.baseTransactions,BaseTransactionName.INPUT)
    const uniqueAddresses: { addressHash: string; amounts: any; }[] = []
    const uniqueAddrMap = getUniqueAddrMap(fromAddresses)
    for(const [key, value] of Object.entries(uniqueAddrMap)) {
        uniqueAddresses.push({addressHash: key, amounts: value})
    }

    const isGenerate = txData.type === transactionTypes.TokenGeneration.type;
    const currencyHash = getTxCurrencyHash(txData.baseTransactions, txData.type);
    const fees = getTxFees(txData.baseTransactions, txData.type);
    const t = {
      hash: txData.hash,
      attachmentTime: txData.attachmentTime,
      type: txData.type,
      baseTransactions: txData.baseTransactions,
      trustChainTrustScore: txData.trustChainTrustScore,
      fees,
      currencyHash: isGenerate ? undefined : currencyHash,
      currencySymbol: isGenerate ? getTxCurrencySymbol(txData.baseTransactions) : undefined,
      amount: getTxAmount(txData.baseTransactions, txData.type, currencyHash),
      transactionConsensusUpdateTime: txData.transactionConsensusUpdateTime,
      uniqueAddresses,
      nodeHash: txData.nodeHash,
      description: txData.transactionDescription === "User Transaction" ? 'Empty' : txData.transactionDescription
    }
    setMappedTransaction(t);
    },[]);

  useEffect(() => {
    if(locationState?.hash && locationState?.hash !== mappedTransaction?.hash) {
      mapTransaction(locationState);
      return
    }

    const txhash = match?.params?.txhash;
    if(txhash && (!mappedTransaction?.hash || (typeof mappedTransaction?.hash === "string" && txhash !== mappedTransaction?.hash))) {
      dispatch(getTransaction(txhash));
      return;
    };
    
    if(mappedTransaction?.hash && connected) dispatch(transactionSubscription({hash: mappedTransaction?.hash}));

    return () => {
      dispatch(unsubscribe({hash: mappedTransaction?.hash}));
    }

  },[dispatch, locationState, mapTransaction, match?.params?.txhash, connected, mappedTransaction?.hash]);
  
  useEffect(() => {
    if(transaction) {
      mapTransaction(transaction);
    };
    
    return () => {
      dispatch(resetTransaction())
    }
    
  },[transaction, dispatch, mapTransaction]);
  
  const renderTypeDetails = useCallback((type: string) => {
    if(!mappedTransaction || type.toLowerCase() === 'zerospend') return null
    
    switch (type) {
        case transactionTypes.TokenMinting.type:
            const TMBT = getBaseTxByName(mappedTransaction.baseTransactions ,BaseTransactionName.TOKEN_MINT)
            return <MintDetails to={TMBT[0].tokenMintingServiceData.receiverAddress} token={asset} />
        case transactionTypes.TokenGeneration.type:
            return <GenerateDetails token={asset} txCurrencySymbol={mappedTransaction.currencySymbol}/>
        case transactionTypes.EventHardFork.type:
            return null
        case transactionTypes.Transfer.type:
        default:
            const RBT = getBaseTxByName(mappedTransaction.baseTransactions,BaseTransactionName.RECEIVER)
            return <TransferDetails to={RBT[0].addressHash} currencyHash={mappedTransaction.currencyHash} />
    }
  },[mappedTransaction, asset]);

  return useMemo(() => mappedTransaction && (
    <div className='inner_page transaction_details'>
      <div className="inner_header">
        <Label label="Transaction details" className="innerpage_title" />
        <Chip label="Transaction"/>
      </div>
      <Row className='hash'>
        <Label label={hash.label} tooltip={hash.tooltip}/>
        <ValueController value={mappedTransaction.hash} copy="green" maxWidth/>
      </Row>
      
      <Row className='attachmentTime'>
        <Label label={attachmentTime.label} tooltip={attachmentTime.tooltip}/>
        {timestampToDisplay(mappedTransaction.attachmentTime)}
      </Row>

      <Row className='type'>
        <Label label={type.label} tooltip={type.tooltip}/>
        <Value value={transactionTypes[mappedTransaction.type].title} className="row_value"/>
      </Row>

      <Row className='status'>
        <Label label={status.label} tooltip={status.tooltip}/>
        <Value value={mappedTransaction.transactionConsensusUpdateTime ? txStatuses.confirmed : txStatuses.pending} className="row_value"/>
      </Row>

      <Row className='trustScore'>
        <Label label={trustScore.label} tooltip={trustScore.tooltip}/>
        <Value value={Math.floor(Number(mappedTransaction.trustChainTrustScore))} className="row_value"/>
      </Row>
      
      {renderTypeDetails(transactionTypes[mappedTransaction.type].type)}
      
      {!mappedTransaction.uniqueAddresses.length ? null : mappedTransaction.uniqueAddresses.length > 1 ? 
        <Expand className={`from address ${mappedTransaction.uniqueAddresses.length > 2 ? 'expand_rows_bg' : ''}`} visible={
          <>
            <Label label={from.label} tooltip={from.tooltip} />
            <Address addressesLength={mappedTransaction.uniqueAddresses.length}/>
          </>}>
          <AmountPerAddress addresses={mappedTransaction.uniqueAddresses} token={asset}/>
        </Expand>
        : <Row className="from">
          <Label label={from.label} tooltip={from.tooltip}/>
          <ValueController
            className="value_link"
            onClickValue={() => navigate(`/address/${mappedTransaction.uniqueAddresses[0].addressHash}`)} 
            value={mappedTransaction.uniqueAddresses[0].addressHash}
            copy="green"
            maxWidth
          />
        </Row>
      }

      <Row className='amount'>
        <Label label={amount.label} tooltip={amount.tooltip}/>
        <Value value={commaFormatted(valueMaxLength(mappedTransaction.amount, 8, 14))} suffix={asset.symbol} className="row_value"/>
      </Row>

      <Expand className="fees" visible={
        <>
        <Label label={fees.label} tooltip={fees.tooltip}/>
        <Value value={mappedTransaction.fees.totalFees} suffix={cotiAsset.symbol} className="row_value"/>
        </>
      }>
        <Row className="expand_row">
          <Label label={networkFees.label} tooltip={networkFees.tooltip}/>
          <Value value={mappedTransaction.fees.financialFee} suffix={cotiAsset.symbol} className="row_value"/>
        </Row>

        <Row className="expand_row">
          <Label label={fullNodeFees.label} tooltip={fullNodeFees.tooltip}/>
          <Value value={mappedTransaction.fees.nodeFee} suffix={cotiAsset.symbol} className="row_value"/>
        </Row>
      </Expand>

      <Row className='processingNode'>
        <Label label={processingNode.label} tooltip={processingNode.tooltip}/>
        <IconLabel id="node_icon" opt={{nodeHash:mappedTransaction.nodeHash, label: nodeDeatils?.name ?? mappedTransaction.nodeHash}} /> { /* Todo: get node details */ }
      </Row>

      <Row className='confirmationTime'>
        <Label label={confirmationTime.label} tooltip={confirmationTime.tooltip}/>
        {mappedTransaction.transactionConsensusUpdateTime ? timestampToDisplay(mappedTransaction.transactionConsensusUpdateTime) : 'Pending'}
      </Row>

      {transactionTypes[mappedTransaction.type].type === transactionTypes.Transfer.type && (
        <Row className='description'>
          <Label label={config.transactionDetails.description.label} tooltip={config.transactionDetails.description.tooltip}/>
          <Value value={mappedTransaction.description} className="row_value"/>
        </Row>
      )}
    </div>
  // eslint-disable-next-line
  ), [mappedTransaction, asset, nodeDeatils])
}

export default TransactionDetails;