import React from 'react';
import { useMatch } from 'react-router-dom';
import styles from './PageLayout.module.scss';

type Props = {
  children: JSX.Element[]
}

const PageLayout: React.FC<Props> = (props) => {
  const isTxDetails = useMatch('/transaction/:txhash');

  return (
    <div className={`${styles.page_layout} ${isTxDetails !== null ? styles.inner : ''}`}>
        {props.children}
    </div>
  )
}

export default PageLayout;