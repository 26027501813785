import { getAddressTransactions, getLastTransacions,getTokenTransactions,selectTransactions } from './transactionsSlice';
import { subscribe, unsubscribe } from 'features/explorer/explorerSlice';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Row from 'components/Elements/Table/Transactions/Row';
import RowHeader from 'components/Elements/Table/RowHeader';
import Label from 'components/Elements/Label/Label';
import { config } from 'config/config';
import { useMatch, useLocation } from 'react-router-dom';
import Paginator from 'components/Elements/Paginator';
import { selectExplorer } from 'features/explorer/explorerSlice';

export function Transactions() {
  const dispatch = useAppDispatch();
  const { transactions, lastTransactions, totalTransactions, connected, offset, isLoading } = useAppSelector(selectTransactions);
  const location = useLocation();
  const matchToken = useMatch('token/:tokenhash');
  const tokenHash = matchToken?.params?.tokenhash;
  const matchAddress = useMatch('/address/:addresshash');
  const addressHash = matchAddress?.params?.addresshash;
  const [ currentPage, setCurrentPage ] =  useState(1);
  const {windowDimensions: {isMobile, isPortraitTablet, isTablet}} = useAppSelector(selectExplorer);
  const pgSize = useMemo(()=>location.pathname === "/" ? 8 : (matchAddress || matchToken) ? 5 : 10,[matchAddress, matchToken, location.pathname]);

  const transctionsByLocation = useMemo(()=>{
    if(location.pathname === '/' || location.pathname === "/transactions" || tokenHash === config.cotiAsset.currencyHash) {
      return lastTransactions;
    }
    return transactions;
  },[transactions, lastTransactions, location.pathname, tokenHash]);

  const tableLabel = useMemo(()=>{

    if(location.pathname === '/') {
      return "Latest Transactions";
    }
    if(location.pathname === "/transactions") {
      return "Transactions";
    }
    
    if(location.pathname.includes('address')) {
      return "Address Transactions";
    }

    if(location.pathname.includes('token')) {
      return "Token Transactions";
    }

    return "";

  },[location.pathname]);

  useEffect(() => {
    setCurrentPage(1);

    if(location.pathname === '/' || location.pathname === "/transactions") {
      dispatch(getLastTransacions({limit: location.pathname === '/' ? 8:10, offset: 0}))
    }

    if(tokenHash) {
      dispatch(getTokenTransactions({currencyHash: tokenHash, limit: 5,offset: 0}));
    }
    
    if(addressHash) {
      dispatch(getAddressTransactions({addressHash, limit: 5, offset: 0}));  
    }
    
    return () => {
      setCurrentPage(1);
    }
    // eslint-disable-next-line
  }, [dispatch, location.pathname]);
  
  useEffect(() => {
    if(!connected) return;
    if(location.pathname === '/' || location.pathname === "/transactions") {
      dispatch(subscribe({transactions: true}))
    }
    return () => {
      if(location.pathname === '/' || location.pathname === "/transactions") {
        dispatch(unsubscribe({transactions: true}))
      }
    }
  }, [dispatch, connected, location.pathname]);
  
  const newOffset: number = useMemo(()=>currentPage === 1 ? 0 : (currentPage - 1) * pgSize ,[currentPage, pgSize]);

  useEffect(()=>{
    if(offset === newOffset) return;

    if(tokenHash) {
      dispatch(getTokenTransactions({currencyHash: tokenHash, limit: 5, offset: newOffset}));
      return
    }
    if(addressHash) {
      dispatch(getAddressTransactions({addressHash, limit: 5, offset: newOffset}));  
      return
    }
    dispatch(getLastTransacions({limit: location.pathname === '/' ? 8:10, offset: newOffset}));  
    // eslint-disable-next-line
  },[newOffset]);

  const TransactionsPaginator = useCallback(() => (
    <Paginator
      currentPage={currentPage}
      totalRecords={totalTransactions}
      pgSize={pgSize}
      onLastClick={() => totalTransactions && setCurrentPage(Math.ceil(totalTransactions / pgSize))}
      onFirstClick={() => setCurrentPage(1)}
      onBackClick={() => setCurrentPage(currentPage - 1)}
      onFwdClick={() => setCurrentPage(currentPage + 1)}
      isLoading={isLoading}
    />
  ), [currentPage, isLoading, pgSize, totalTransactions])
  
  return useMemo(()=> ( <>
    <div className="transactions">
      <div className="table multi_pages">
        <div className="headline_wrapper">
          <Label label={tableLabel} className="row_title" />
          {!isMobile && location.pathname !== '/' && <TransactionsPaginator/>}
        </div>
        {!isMobile && <RowHeader columns={config.tableFields.transactions[isPortraitTablet ? 'portraitTablet' : isTablet ? 'tablet' : 'desktop']} className="transaction_row" />}
        {transctionsByLocation?.map((transaction, idx) => (
          <Row key={transaction.hash} transactionData={transaction} />
        ))}
      </div>
    </div>
    
    {location.pathname !== '/' && <TransactionsPaginator/>}

    </>
  ),[tableLabel, isMobile, location.pathname, TransactionsPaginator, isPortraitTablet, isTablet, transctionsByLocation]);
}