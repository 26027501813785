import React, { useMemo } from 'react';
import Label from '../Label/Label';
import Column from './Column';
import './Table.scss';

const RowHeader = ({columns, className}: any) => useMemo(() => (
    <div className={`table_row row_header ${className ?? ''}`}>
        {Object.entries(columns).map((col: any) => (
            <Column key={col[0]} colKey={col[0]} className={`row_value ${col[0]}`}>
                <Label label={col[1].label} tooltip={col[1]?.tooltip}/>
            </Column>
        ))}
    </div>
), [className, columns])

export default RowHeader;