import Expand from 'components/Elements/Expand/Expand';
import Label from 'components/Elements/Label/Label';
import Row from 'components/Elements/Row/Row';
import Value from 'components/Elements/Value/Value';
import ValueController from 'components/Elements/ValueController/ValueController';
import { config } from 'config/config';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { commaFormatted } from 'utilities';

const TokenDetails = ({token, txCurrencySymbol}:any) => {
    const navigate = useNavigate();
    return useMemo(() => {
        const { token: currencySymbol, currencyName, currencyHash, totalSupply, scale, originatorHash } = config.transactionDetails;
        return (
            <>
                <Expand className="token expand_rows_bg" disabled={!token.currencyHash} visible={<>
                    <Label label={currencySymbol.label} tooltip={currencySymbol.tooltip} />
                    <Value value={txCurrencySymbol ?? token.symbol} className="row_value" />
                </>}>
                    {token.currencyHash && (
                        <>
                            <Row className="expand_row">
                                <Label label={currencyHash.label} tooltip={currencyHash.tooltip} />
                                <ValueController
                                    value={token.currencyHash}
                                    copy="green"
                                    maxWidth
                                    className="value_link"
                                    onClickValue={() => navigate(`/token/${token.currencyHash}`)}
                                />
                            </Row>

                            <Row className="expand_row">
                                <Label label={currencyName.label} tooltip={currencyName.tooltip} />
                                <Value value={token.name} className="row_value" />
                            </Row>

                            <Row className="expand_row">
                                <Label label={totalSupply.label} tooltip={totalSupply.tooltip} />
                                <Value value={commaFormatted(token.totalSupply)} className="row_value" />
                            </Row>

                            <Row className="expand_row">
                                <Label label={scale.label} tooltip={scale.tooltip} />
                                <Value value={token.scale} className="row_value" />
                            </Row>

                            <Row className="expand_row">
                                <Label label={originatorHash.label} tooltip={originatorHash.tooltip} />
                                <ValueController value={token.originatorHash} copy="green" maxWidth="448px" />
                            </Row>
                        </>
                    )}
                </Expand>
            </>
        );
    }, [token, txCurrencySymbol, navigate]);
}

export default TokenDetails;