import React, { useMemo } from 'react';
import Copy from 'components/Elements/Copy/Copy';
import Tooltip from 'components/Elements/Tooltip/Tooltip';
import './ValueController.scss';

const ValueController = ({value, className, tooltip, copy, maxWidth, onClickValue }: any) => useMemo(() => (
  <div className={`value_controller ${className ?? ''}`}>
    <p className={`value ${maxWidth ? 'short_value' : ''}`} style={{ maxWidth }} onClick={onClickValue}>
      {value}
    </p>

    {tooltip && <Tooltip text={tooltip} />}
    {copy && <Copy text={value} copy={copy} />}
  </div>
), [className, copy, maxWidth, tooltip, value, onClickValue])
export default ValueController;