import { BigDecimal } from "@coti-io/crypto/dist/utils/utils"
import api from "api/api"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { Buffer } from "buffer"
import { BaseTransactionName, config, transactionTypes } from "config/config"
import { getCotiPrice, selectExplorer } from "features/explorer/explorerSlice"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { IBaseTransaction } from "./types"

export const copy = (value: string) => {
    var textField = document.createElement('textarea')
    textField.innerText = value
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    return textField.remove()
}

const removeZerosFromEndOfNumber = (number:string) => {
    if (number.includes('.')) {
        while (number.charAt(number.length - 1) === "0") {
            number = number.substring(0, number.length - 1);
        }
        if (number.charAt(number.length - 1) === ".")
            number = number.substring(0, number.length - 1);
    }
    return number;
}

export const commaFormatted = (amount:string | number, fixed?: number) => {
    if(typeof amount === "string" && !amount.includes(".")) return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    amount = removeZerosFromEndOfNumber(amount.toString());
    var parts = amount.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(typeof fixed === "number") {
        parts[1] = parts[1]?.slice(0,fixed);
    }
    
    return parts[1] ? removeZerosFromEndOfNumber(parts.join(".")) : parts[0];
}


export const getBaseTxByName = (baseTransactions: any, name: string) => baseTransactions.filter((btx: any) => btx.name === name)

export const timestampToDisplay = (timestamp: any) => moment.utc(timestamp * 1000).format('DD MMM, YYYY, HH:mm:ss')  + ' UTC'

export const toBuffer = (data: any) => {
    const buffer = Buffer.from(data, 'utf8').toString('base64');
    return `data:image/svg+xml;base64,${buffer}`;
}

export const getTxCurrencyHash = (baseTransactions: IBaseTransaction[], txType:string) => {
    switch (txType) {
        case transactionTypes.TokenGeneration.type:
            return undefined;
        case transactionTypes.TokenMinting.type:
            const TMBT = baseTransactions.find((btx: IBaseTransaction) => btx.name === BaseTransactionName.TOKEN_MINT)
            return TMBT?.tokenMintingServiceData?.mintingCurrencyHash ?? null
        case transactionTypes.Transfer.type:
        default:
            const IBT = baseTransactions.filter(btx => btx.name === BaseTransactionName.INPUT)
            const token = IBT.find(btx => btx.currencyHash !== null && btx.currencyHash !== config.cotiAsset.currencyHash)
            return token ? token.currencyHash : config.cotiAsset.currencyHash
    }
}

export const getTxAmount = (baseTransactions: IBaseTransaction[], txType: string, currencyHash:string|null|undefined) => {
    if(currencyHash === undefined) return "0";
    const isCoti = currencyHash === config.cotiAsset.currencyHash || currencyHash === null
    const _currencyHash = isCoti ? config.cotiAsset.currencyHash : currencyHash
    let amount = new BigDecimal('0');
    switch (txType) {
        case transactionTypes.TokenMinting.type:
            const mintingAmount = baseTransactions.find((tx:IBaseTransaction) => tx.name === BaseTransactionName.TOKEN_MINT)?.tokenMintingServiceData?.mintingAmount
            amount = mintingAmount ? amount.add(new BigDecimal(mintingAmount.toString())) : amount;
            break;
        case transactionTypes.TokenGeneration.type:
            amount.toPlainString();
            break;
        case transactionTypes.Transfer.type:
        default:
            const transferTx = baseTransactions.filter((btx:IBaseTransaction) => btx.name === BaseTransactionName.INPUT);
            const txByHash = transferTx.filter((tx:any) => tx.currencyHash === _currencyHash)
            const sumTx = txByHash.length ? txByHash : transferTx
            sumTx.forEach((t:any) => amount = amount.add(new BigDecimal(t.amount.toString())))
    }
    
    return absAmount(amount);
}

export const getTxFees = (baseTransactions: IBaseTransaction[], txType: string) => {
    let FFBT = baseTransactions.find((tx: IBaseTransaction) => tx.name === BaseTransactionName.FULL_NODE_FEE);
    if(!FFBT) return {financialFee: new BigDecimal("0").toPlainString(), nodeFee: new BigDecimal("0").toPlainString(), totalFees: new BigDecimal("0").toPlainString()};
    let financialFee
    let nodeFee = new BigDecimal(FFBT.amount.toString());
    switch (txType) {
        case transactionTypes.TokenMinting.type:
            let TMBT = baseTransactions.find((tx: IBaseTransaction) => tx.name === BaseTransactionName.TOKEN_MINT)
            financialFee = new BigDecimal(TMBT ? TMBT.amount.toString() : "0" );
            break;
        case transactionTypes.TokenGeneration.type:
            let TGBT = baseTransactions.find(tx => tx.name === BaseTransactionName.TOKEN_GENERATION_FEE)
            financialFee = new BigDecimal(TGBT ? TGBT.amount.toString() : "0");
            break;
        case transactionTypes.Transfer.type:
        default:
            let NFBT = baseTransactions.find(tx => tx.name === BaseTransactionName.NETWORK_FEE)
            financialFee = new BigDecimal(NFBT ? NFBT.amount.toString() : "0");
    }
    return {
        financialFee: financialFee.toPlainString(),
        nodeFee: nodeFee.toPlainString(),
        totalFees: nodeFee.add(financialFee).toPlainString()
    }
}

export const getTxCurrencySymbol = (baseTransactions: IBaseTransaction[]) => {
    let TGBT = baseTransactions.find((tx: IBaseTransaction) => tx.name === BaseTransactionName.TOKEN_GENERATION_FEE);
    return TGBT?.tokenGenerationServiceData?.originatorCurrencyData.symbol ?? undefined;
}

export const absAmount = (amount: string | BigDecimal ) => {
    let amountBD = new BigDecimal(amount);
    const zero = new BigDecimal("0");
    const isAmountLowerThen = amountBD.compareTo(zero) < 0;
    if(isAmountLowerThen) {
        amountBD = amountBD.multiply(new BigDecimal('-1'));
    }
    return amountBD.toPlainString();
}

export const useCotiToUsd = () => {
    const { price } = useAppSelector(selectExplorer);
    const dispatch = useAppDispatch();
    
    const cotiToUsd = useCallback((totalCoti: string | null, fixed?: number)=>{
        if(!totalCoti || !price) return 'N/A';
        const totalInUSD = new BigDecimal(totalCoti).multiply(new BigDecimal(price)).toPlainString();
        return commaFormatted(totalInUSD, fixed && fixed > -1 ? fixed : 2);
    },[price])

    useEffect(()=>{
        if(!price) {
            dispatch(getCotiPrice());
        }
    },[price, dispatch]);

    return cotiToUsd
}

export const useNode = (nodeHash: string | undefined) => {
    const [node, setNode] = useState<any>();
    
    const getNodeDetails = useCallback(async(cb:any) => {
        try {
            if(!nodeHash) return null;
            const res = await api.getNodeInfoByHash(nodeHash);
            if(res) cb(res.data);
        } catch (error) {
            console.log("Error: ", error);
        }
    },[nodeHash])

    useEffect(()=>{
        if(!nodeHash) return;
        let isUnmount = false;
        getNodeDetails((nodeDetails: any) => {
            if(isUnmount) return 
            setNode(nodeDetails);
        })
        return () => {
            isUnmount = true;
        }
    },[nodeHash, getNodeDetails])

    return node
}

export const getUniqueAddrMap = (fromAddresses:any) => fromAddresses.reduce((acc: any, cur:any) => {
    acc[cur.addressHash] = acc[cur.addressHash] || [];
    acc[cur.addressHash].push({amount: absAmount(cur.amount), currencyHash: cur.currencyHash || config.cotiAsset.currencyHash});
    return acc;
}, {} );

export const valueMaxLength = (value:string, scale:number, maxLength:number = 14) => {
    if (!value.includes('.')) return value
    const splitedValue = value.split('.')
    if (splitedValue[0].length >= maxLength) return splitedValue[0]
    let valueDecimals = splitedValue[1].slice(0, scale)
    valueDecimals = valueDecimals.slice(0, maxLength - splitedValue[0].length)
    return `${splitedValue[0]}.${valueDecimals}`
}