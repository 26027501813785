import React, { useMemo } from "react";
import "./Row.scss";

export const Row = ({ children, className }:{children: React.ReactNode; className?: string;}) => useMemo(() => (
  <div className={`row_component ${className ?? ''}`}>
    {children}
  </div>
), [children, className]);

export default Row;
