import React, { useEffect, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { config } from 'config/config';
import Label from 'components/Elements/Label/Label';
import Row from 'components/Elements/Row/Row';
import ValueController from 'components/Elements/ValueController/ValueController';
import Value from 'components/Elements/Value/Value';
import { commaFormatted, useCotiToUsd } from 'utilities';
import Expand from 'components/Elements/Expand/Expand';
import AddressTokens from './AddressTokens';
import './AddressDetails.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addressSubscription, selectTransactions } from 'features/transactions/transactionsSlice';
import { Transactions } from 'features/transactions/Transactions';
import Chip from 'components/Elements/Chip/Chip';
import { unsubscribe } from 'features/explorer/explorerSlice';


const AddressDetails = () => {
    const { hash, amount, tokens } = config.addressDetails;
    const match = useMatch('/address/:addresshash');
    const dispatch = useAppDispatch();
    const addressHash = match?.params?.addresshash;
    const {nativeBalance, transactionsBalances, connected} = useAppSelector(selectTransactions);
    const cotiToUsd = useCotiToUsd();
    
    useEffect(()=>{
        if(addressHash && connected) {
            dispatch(addressSubscription({address: addressHash}));
        }
        
        return () => {
            dispatch(unsubscribe({address: addressHash}));
        }

    },[connected, addressHash, dispatch]);

    return useMemo(() => addressHash ?  (<>
        <div className='inner_page address_details'>
            <div className="inner_header">
                <Label label="Address Details" className="innerpage_title" />
                <Chip label="Address"/>
            </div>
            <Row className="hash">
                <Label label={hash.label} tooltip={hash.tooltip}/>
                <ValueController value={addressHash} copy="green" maxWidth/>
            </Row>

            {nativeBalance && <Row className="balance">
                <Label label={amount.label} tooltip={amount.tooltip}/>
                <div className="values">
                    <Value value={`${commaFormatted(nativeBalance)} COTI `} className="row_value"/>
                    <div className="secondary_value">
                        (<Value value={cotiToUsd(nativeBalance, 4)} prefix={'$'} className="row_value"/>)
                    </div>
                </div>
            </Row>}

            {transactionsBalances && <Expand className={`address_tokens ${Object.keys(transactionsBalances).length > 2 ? 'expand_rows_bg' : ''}`} visible={
            <>
                <Label label={tokens.label} tooltip={tokens.tooltip}/>
                <Value value={commaFormatted(`${Object.keys(transactionsBalances).length}`)} className="row_value"/>
            </>}>
                <AddressTokens tokens={transactionsBalances}/>
            </Expand> }
        </div>
        <Transactions />
        </>
    // eslint-disable-next-line
  ):null, [addressHash, nativeBalance, cotiToUsd, transactionsBalances]);
}

export default AddressDetails;