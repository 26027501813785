import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getToken, getTokenIcon, selectTokens, getTokenBySymbol } from 'features/tokens/tokensSlice';
import { IToken } from 'utilities/types';
import { config } from 'config/config';
import './Spinner.scss';
import { useNavigate } from 'react-router';
import api from 'api/api';
import { toBuffer } from 'utilities';
import ValueController from 'components/Elements/ValueController/ValueController';
import './IconLabel.scss';
import { selectExplorer } from 'features/explorer/explorerSlice';
import defaultNodeIcon from '../../../images/icons/menu/nodes.svg';

interface Iopt {
  currencyHash?: string | null | undefined;
  currencySymbol?: string | undefined;
  txStatus?:boolean;
  className?:string;
  label?: string;
  icon?: string
  nodeHash?: string;
}

type Props = {
  id: string;
  opt: Iopt;
}

const IconLabel:React.FC<Props>= ({id, opt}) => {
  return useMemo(() => (
    <div id={id} className={`icon_label ${opt.className ?? ''}`}>
      {id === "label" ?  <Label label={opt.label} /> 
      : id === "node_icon" ? <NodeIcon opt={opt}/>
      : <IconComponent opt={opt} id={id}/> }
    </div>
  ), [id, opt]);
}

export default IconLabel;

const NodeIcon:React.FC<{opt: Iopt}> = ({opt}) => {

  const [nodeIcon, setNodeIcon] = useState<any>(null);

  const getNodeIcon = useCallback(async() => {
    if(!opt.nodeHash) return;
    try {
      const res = await api.getNodeIcon(opt.nodeHash);
      if(!res.config.data) throw new Error('error');
      const bufferIcon = toBuffer(res.data);
      setNodeIcon(bufferIcon);
    } catch (error) {
      setNodeIcon(defaultNodeIcon)
    }
  },[opt.nodeHash]);

  useEffect(()=>{
    getNodeIcon();
  },[getNodeIcon]);

  return !nodeIcon ? <Spinner className="spinner-stat" title="Pending" /> 
  : <>
    <img className='icon' src={nodeIcon} alt="icon"/>
    <ValueController
      value={opt.label}
      maxWidth
    />
  </>
}

const IconComponent:React.FC<{opt: Iopt, id:string}> = ({opt, id}) => {
  const navigate = useNavigate();
  const {windowDimensions: {isMobile}} = useAppSelector(selectExplorer);
  const [ token ] = useToken(opt.currencyHash, opt.currencySymbol, opt.txStatus);
  const isClickable = token.currencyHash && token.currencyHash !== 'undefined' && token.currencyHash !== config.cotiAsset.currencyHash;

  const TokenNameAndSymbol = useCallback(() => (
      <div className="token_full">
        <span title={token.name}>{token.name ?? ''}</span>
        (<Label label={`${opt.currencySymbol ?? token.symbol}`} />)
      </div>
    ),[opt.currencySymbol, token.name, token.symbol],
  )
  
  const renderById = useCallback(() => {
    switch (id) {
      case 'search_result_icon':
        return token.name && isMobile ? <TokenNameAndSymbol/> : <Label label={opt.label} />
      case 'tokenlist':
        return token.name && !isMobile ? <TokenNameAndSymbol/> : <Label label={opt.currencySymbol ?? token.symbol} />
        case 'icon_only':
          return;
      default:
        return <Label label={opt.currencySymbol ?? token.symbol} />
    }
  },[TokenNameAndSymbol, id, isMobile, opt, token],
  )
  

  return useMemo(()=> token && (
    <div className={`icon_label ${isClickable ? 'label_link' : ''}`} onClick={() => isClickable ? navigate(`/token/${token.currencyHash}`) : null} >
      {token.symbol === "COTI" ? <img className='icon' src={require('images/logos/coti_circle.svg').default} alt={token.symbol} />
        : token.icon ? <img className='icon' src={token.icon} alt={token.symbol} />
          : <Spinner className="spinner-stat" title="Pending" />}
      {renderById()}
    </div>
  ),[token, isClickable, renderById, navigate]);

}

const Label = ({label, onClickValue} : any ) => {
    return <p className='label' onClick={onClickValue}>
      {label}
    </p>
}

export const Spinner = ({className, title, type = "default"} : any ) => {
    return (<span 
      className={`spinner-component ${type ?? ''} ${className ?? ''}`}
      title={title}
      />
    )
}


export const useToken = (currencyHash?: string | null | undefined, currencySymbol?:string, txStatus?: boolean) => { 
  const { tokens } = useAppSelector(selectTokens);
  const dispatch = useAppDispatch();
  const [token, setToken] = useState({} as IToken);
  const [fetched, setFetched] = useState<boolean>(false)
  
  const getTokenFromState = useCallback(()=>{
    if(currencyHash) return tokens[currencyHash]
    const tokensArray:IToken[] = Object.values(tokens);
    const tokenExist:IToken|undefined = tokensArray.find((t: IToken)=> t.symbol === currencySymbol);
    return tokenExist
  },[tokens, currencyHash, currencySymbol]);

  const tokenDetails = useMemo(()=>typeof currencyHash === "string" || typeof currencySymbol === "string" ? getTokenFromState() : false, [currencySymbol,currencyHash, getTokenFromState]);
  
  useEffect(()=>{
    if(!tokenDetails || !token.currencyHash) return
    if(token.currencyHash === tokenDetails.currencyHash){
      if(token.circulatingSupply !== tokenDetails.circulatingSupply) setToken(tokenDetails);
    }
  },[tokenDetails, token.circulatingSupply, token.currencyHash]);

  useEffect(()=>{
    if((typeof currencySymbol !== "string" && currencyHash === undefined) || token.icon) return;
    if(currencyHash === null || currencyHash === config.cotiAsset.currencyHash) return setToken(config.cotiAsset);
    if(tokenDetails && tokenDetails.icon && !token.icon) setToken(tokenDetails);
  },[currencyHash, dispatch, token, tokenDetails, currencySymbol]);

  useEffect(()=> {
    if(!token.currencyHash) return
    if(token.currencyHash !== currencyHash && typeof currencyHash === "string") {
      setToken({} as IToken);
    }
  },[currencyHash, token.currencyHash])
  useEffect(()=> {
    if(!tokenDetails?.symbol || tokenDetails?.icon || fetched) return
    setFetched(true)
    dispatch(getTokenIcon({currencyHash: currencyHash ?? tokenDetails.currencyHash, symbol: tokenDetails.symbol}));
  },[currencyHash, currencySymbol, dispatch, tokenDetails, tokens, fetched]);

  useEffect(()=> {
    if(typeof currencyHash !== "string") return;
    if(currencyHash === null || currencyHash === config.cotiAsset.currencyHash) return
    if(tokenDetails || token.currencyHash) return
    dispatch(getToken(currencyHash));
  },[currencyHash, currencySymbol, dispatch, tokenDetails, token.currencyHash])

  useEffect(()=>{
    if(!txStatus || typeof currencySymbol !== "string" || typeof currencyHash === "string") return;
    if(tokenDetails && !token.currencyHash) return
    if(token.icon) return
    dispatch(getTokenBySymbol(currencySymbol));
  },[currencySymbol, dispatch, token.currencyHash, tokenDetails, txStatus, currencyHash, token.icon]);
  
  return useMemo(() => [token], [token]);
}