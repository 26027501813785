import api from 'api/api';

export function searchRequest(criteira: string) {
  return api.search(criteira);
}

export function fetchPrice() {
  return api.getPrice();
}

export function fetchTreasuryTVL() {
  return api.getTreasuryTVL()
}

export function fetchTxConfirmationTimeAVG() {
  return api.getTxConfirmationTime()
}
